<template>
  <section id="banner-features" class="section">
    <div class="container">
      <div class="columns is-centerd is-vcentered">
        <div class="column">
          <div data-scroll class="columns is-mobile is-gapless">
            <div
              class="column is-4 
                                  has-centered-aligned-content"
            >
              <font-awesome-icon icon="shipping-fast" size="2x" />
            </div>
            <div class="column is-8">
              <h1><b>MIỄN PHÍ GIAO HÀNG.</b></h1>
              <p>Áp dụng cho đơn hàng trên 100.000</p>
            </div>
          </div>
        </div>

        <div data-scroll class="column">
          <div class="columns is-mobile is-gapless">
            <div
              class="column is-4 
                                  has-centered-aligned-content"
            >
              <font-awesome-icon icon="piggy-bank" size="2x" />
            </div>
            <div class="column is-8">
              <h1><b>TIẾT KIỆM 20%.</b></h1>
              <p>Đặt mua định kì tối thiểu 1 tuần 1 lần</p>
            </div>
          </div>
        </div>

        <div data-scroll class="column">
          <div class="container">
            <div class="columns is-mobile is-gapless">
              <div
                class="column is-4
                                  has-centered-aligned-content"
              >
                <font-awesome-icon icon="shopping-bag" size="2x" />
              </div>
              <div class="column is-8">
                <h1><b>MUA HÀNG ONLINE</b></h1>
                <p>Đặt hàng với 1 cú click chuột.</p>
              </div>
            </div>
          </div>
        </div>

        <div data-scroll class="column">
          <div class="columns is-mobile is-gapless">
            <div
              class="column is-4
                                  has-centered-aligned-content
                                  "
            >
              <div class="is-pulled-right">
                <font-awesome-icon :icon="['fab', 'envira']" size="2x" />
              </div>
            </div>
            <div class="column is-8">
              <h1><b>100% TƯƠI XANH</b></h1>
              <p>Từ vườn rau đến ngay bàn ăn của mọi gia đình.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ScrollOut from 'scroll-out'

export default {
  name: 'HomeBannerFeature',
  mounted() {
    this.so = ScrollOut({
      scope: this.$el
    })
  },
  destroyed() {
    this.so.teardown()
  }
}
</script>

<style lang="scss">
@import '@/assets/app-variables.scss';

#banner-features {
  display: flex;
  background: rgb(133, 185, 53);
  background: linear-gradient(
    90deg,
    rgba(133, 185, 53, 1) 100%,
    rgba(124, 218, 163, 1) 100%
  );
  color: $text-inverted-color;
}

.column.has-centered-aligned-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  #banner-features {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
</style>

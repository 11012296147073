<template lang="pug">
section.section
    div.container#admin-main
        aside.menu
            div.columns
                div.column.is-one-quarter
                    div.menu-label Dash Board
                    ul.menu-list.pl-4
                        li(v-for="(item, index) in items")
                            a( :class="{ 'is-active': item.active }" @click="select(index)") {{item.text}}
                div.column
                    div(v-if="isProfileActive")
                        ProfileMain
                    div(v-else)
                        div Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laboriosam sint, soluta aut aliquam amet ipsum! Inventore nemo voluptatibus asperiores ex, eius at. Nam sed voluptates nobis cupiditate enim cumque vel!
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import ProfileMain from '@/components/profiles/ProfileMain.vue'

var items = [
  { text: 'Profile', active: true },
  { text: 'Your Posts', active: false }
]

export default {
  name: 'AdminMain',
  components: {
    ProfileMain
  },
  data() {
    return {
      items: items
    }
  },
  computed: {
    ...mapState('auth', ['status']),
    isProfileActive: function() {
      return this.items[0].active
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.status.loggedIn) {
        vm.$router.push({ name: 'auth-login' })
      }
    })
  },
  methods: {
    select(index) {
      _.forEach(this.items, (item, i) => {
        item.active = i === index
      })
    }
  }
}
</script>

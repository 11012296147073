<template>
  <div id="footer" class="section">
    <figure class="image-bg">
      <img :src="img_url" alt="" class="is" />
    </figure>
    <div class="container">
      <div class="columns">
        <div class="column is-two-thirds is-offset-one-quarter">
          <figure class="logo">
            <img :src="logo_fallback_url" :srcset="logo_url" alt="My logo" />
          </figure>
          <br />
          <div class="columns">
            <div class="column">
              <article>
                <h1 class="subtitle">Về công ty</h1>
                <p>Copyright @ <b>Metro Organico</b></p>
              </article>
              <!-- <article>
                <LocaleSwitcher />
              </article> -->

              <p>
                <span class="right-spacing">
                  <font-awesome-icon
                    :icon="{ prefix: 'fab', iconName: 'facebook' }"
                  />
                </span>
                <a
                  class="fb-page"
                  href="https://facebook.com/metrorganico"
                  target="_blank"
                  >Metro Organico FB page</a
                >
              </p>
            </div>

            <div class="column">
              <article>
                <h1 class="subtitle">Liên hệ</h1>
                <p>
                  <span class="right-spacing">
                    <font-awesome-icon icon="building" />
                  </span>
                  <span>152A Lý Chính Thắng, P7, Q3, Tp. HCM </span>
                </p>
                <p></p>
                <p>
                  <span class="right-spacing">
                    <font-awesome-icon icon="at"
                  /></span>
                  <span> support@metrorganico.com </span>
                </p>
                <p>
                  <span class="right-spacing">
                    <font-awesome-icon icon="phone-alt"
                  /></span>
                  <span> 090 701 1068 </span>
                </p>
              </article>
            </div>

            <mq-responsive target="sm">
              <div class="column is-one-third"></div>
            </mq-responsive>
          </div>
        </div>
      </div>
    </div>
    <AppVersion />
  </div>
</template>

<script>
import AppVersion from '@/components/atomic/AppVersion.vue'
import { MqResponsive } from 'vue3-mq'

export default {
  name: 'AppFooter',
  components: { AppVersion, MqResponsive },
  data() {
    return {
      img_url: 'https://storage.googleapis.com/meganic_assets/footer-bg.png',
      logo_url:
        'https://storage.googleapis.com/meganic_assets/footer-logo-full.svg',
      logo_fallback_url:
        'https://storage.googleapis.com/meganic_assets/footer-logo-full.png'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';
.fb-page {
  color: #fafafa;
  text-decoration: none;
}
.top-spacing,
article {
  padding-top: 1rem;
}

.right-spacing {
  padding-right: 1rem;
}

#footer {
  background-color: $text-color;
  position: relative;
  min-height: 400px;
}

.subtitle,
p,
h1,
h2,
h3,
h4 {
  color: $text-inverted-color;
}

.image-bg {
  height: 300px;
  width: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.image-bg img {
  width: 100%;
  height: 100%;
}

.logo {
  width: 240px;
  height: 60px;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .logo {
    width: 200px;
    height: 40px;
  }

  #footer {
    padding-bottom: 160px;
  }

  .image-bg {
    height: 150px;
    width: 150px;
    position: absolute;
  }
}
</style>

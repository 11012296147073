<template>
  <div id="home-main" class="animate__animated animate__fadeIn">
    <section class="hero is-fullheight" style="background-color: #85b935;">
      <HomeTakeToMotivation />
    </section>

    <HomeTakeToMotivation />
    <HomeBannerFeatures />
    <HomeHydroIntroduction />
    <HomeProductListing />
    <HomePartnership />
    <HomeContactUs />
    <HomeFaqs />
    <AppFooter />
  </div>
</template>

<script>
import HomeTakeToMotivation from '@/components/home/HomeTakeToMotivation'

import HomeBannerFeatures from '@/components/home/HomeBannerFeatures.vue'
import HomeHydroIntroduction from '@/components/home/HomeHydroIntroduction.vue'
import HomeProductListing from '@/components/home/HomeProductListing.vue'
import HomePartnership from '@/components/home/HomePartnership.vue'
import HomeContactUs from '@/components/home/HomeContactUs.vue'
import HomeFaqs from '@/components/home/HomeFaqs.vue'
import AppFooter from '@/components/atomic/AppFooter.vue'

export default {
  name: 'App',
  components: {
    HomeTakeToMotivation,
    HomeBannerFeatures,
    HomeHydroIntroduction,
    HomeProductListing,
    HomePartnership,
    HomeContactUs,
    HomeFaqs,
    AppFooter
  }
}
</script>

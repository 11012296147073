<template>
  <div class="card">
    <div class="is-flex is-horizontal-center">
      <figure id="avatar" class="image is-256x256">
        <image-data
          classes="is-rounded"
          :url="profileImageUrl"
          alt="profile image"
        />
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content has-text-centered">
          <p class="title is-3">
            {{ `${firstName} ${lastName} - ${location}` }}
          </p>
          <p class="subtitle is-3">{{ title }}</p>
          <!-- <blockquote class="blockquote">
            <p>
              {{ quote }}
            </p>
          </blockquote> -->

          <p class="title is-6">{{ qualification }}</p>
          <p class="title is-6">{{ hobby }}</p>
        </div>
      </div>

      <div class="content has-text-justified">
        <p class="has-text-centered title is-size-6">
          {{ briefDescription }}
        </p>
        <br />
        <p class="is-size-6 has-text-weight-medium">
          {{ fullDescription }}
        </p>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPersonalCard',
  props: [
    'firstName',
    'lastName',
    'title',
    'qualification',
    'hobby',
    'location',
    'profileImageUrl',
    'briefDescription',
    'fullDescription',
    'quote'
  ]
}
</script>

<style lang="scss" scoped>
#avatar {
  width: 70%;
}
figure {
  padding: 1rem;
}
.card {
  margin: 1rem 0.5rem;
  min-height: 90vh;
}
.is-horizontal-center {
  justify-content: center;
}

.card-image {
  padding-top: 3rem;
}

.media-content {
  overflow: hidden;
}

.quote {
  margin: 0.5rem 0.2rem 0.5rem 0.2rem;
}

.blockquote {
  padding: 60px 80px 40px;
  position: relative;
}
.blockquote p {
  font-family: 'Utopia-italic';
  font-size: 2.2rem;
  font-weight: 700px;
  text-align: center;
  text-justify: inter-word;
}

.blockquote:before {
  position: absolute;
  font-family: 'FontAwesome';
  top: 0;

  content: '\f10d';
  font-size: 200px;
  color: rgba(0, 0, 0, 0.1);
}

.blockquote::after {
  content: '';
  top: 20px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  border-bottom: 3px solid #11998e;
  height: 3px;
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .blockquote p {
    font-size: 1.5rem;
  }

  .blockquote::after {
    content: '';
    top: 10px;
    left: 50%;
    margin-left: -50px;
    position: absolute;
    border-bottom: 3px solid #11998e;
    height: 2px;
    width: 120px;
  }
}
</style>

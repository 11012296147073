<template lang="pug">
div.columns.is-mobile
    div.column.is-three-fifths.is-offset-one-fifth
        div.card
            div(
                :class="imageClassName" 
                class="base-image-input image is-4by3" 
                @click="chooseImage" 
                :style="{'background-image': `url(${imageData})` }"
                )
                
                span(v-if="imageData" :class="imageClassName" class="placeholder") Choose an image
                input(
                    class='file-input'
                    ref='fileInput'
                    type='file'
                    @input='onSelectFile'
                    )
            div.card-content
                div.content
                    fieldset( v-if="isDisableForm" :disabled="true")                       
                      label.label First Name
                      div.control
                          input(class="input" type="text" :placeholder="currentProfile.firstName" )
                      label.label Last Name
                      div.control
                          input(class="input" type="text" :placeholder="currentProfile.lastName")
                      label.label Email
                      div.control
                          input(class="input" type="text" :placeholder="currentProfile.email")
                      label.label Phone
                      div.control
                          input(class="input" type="text" :placeholder="currentProfile.phoneNumber")

                    fieldset( v-else="isDisableForm" :disabled="false")                       
                      label.label First Name
                      div.control
                          input(v-model="currentProfile.firstName" class="input" type="text")
                      label.label Last Name
                      div.control
                          input(v-model="profile.lastName" class="input" type="text")
                      label.label Email
                      div.control
                          input(v-model="profile.email" class="input" type="text")
                      label.label Phone
                      div.control
                          input(v-model="profile.phoneNumber" class="input" type="text")
                            
            footer.card-footer
                a(class="card-footer-item" v-for="action in actions" @click="handleClick(action)") {{action}}
</template>

<script>
import ImageService from '@/services/image.service.js'
import Profile from '@/models/profile.js'
import { mapState } from 'vuex'
var actions = ['EDIT', 'DELETE']
var editingActions = ['DONE']
const cardClass = {
  active: 'active-editing',
  inactive: 'inactive-editing',
  preview: 'preview'
}

export default {
  name: 'ProfileCard',
  created() {
    if (this.profile) {
      this.currentProfile = this.profile
    }
    this.resetImage()
  },

  data() {
    return {
      actions: actions,
      isDisableForm: true,
      imageClassName: cardClass.inactive,
      imageData:
        'https://versions.bulma.io/0.7.0/images/placeholders/1280x960.png',
      currentFile: null,
      currentProfile: this.profile
        ? this.profile
        : new Profile('John', 'Doe', 'example@example.com', null, null)
    }
  },
  watch: {
    profile(newVal) {
      if (
        (this.currentProfile === null || this.currentProfile !== newVal) &&
        newVal !== null
      ) {
        this.currentProfile = newVal
        this.resetImage()
      }
    }
  },
  methods: {
    handleClick(action) {
      if (action === 'EDIT') {
        this.isDisableForm = false
        this.actions = editingActions
        this.imageClassName = cardClass.active
        return
      }
      if (action === 'DONE') {
        this.isDisableForm = true
        this.actions = actions
        this.imageClassName = cardClass.inactive
        if (this.currentFile) {
          this.uploadImage(this.currentFile)
        }
        return
      }
      if (action === 'DELETE') {
        ImageService.delete(this.imageMetadata.name).then(response => {
          if (response.data.error === null) {
            this.resetImage()
          }
        })
      }
    },

    async uploadImage(file) {
      this.$loadingOverlay.show()
      var imageResponse = await ImageService.uploadImage(file)
      imageResponse.idCloud = imageResponse.id
      delete imageResponse.id
      this.$store
        .dispatch('profile/updateProfileWithImageMetadata', {
          profile: this.profile,
          image: imageResponse
        })
        .then(() => {
          this.$loadingOverlay.hide()
        })
    },

    resetImage() {
      if (this.currentProfile) {
        this.imageData = this.currentProfile.image.publicUrl
      } else if (this.profile) {
        this.imageData = this.profile.image.publicUrl
      } else {
        this.imageData =
          'https://versions.bulma.io/0.7.0/images/placeholders/1280x960.png'
      }
    },

    chooseImage() {
      if (this.imageClassName === cardClass.active) {
        this.$refs.fileInput.click()
      }
    },
    async onSelectFile() {
      const files = this.$refs.fileInput.files

      if (files && files[0]) {
        this.currentFile = files[0]

        const reader = new FileReader()
        reader.onload = e => {
          this.imageData = e.target.result
          this.imageClassName = cardClass.preview
        }
        reader.readAsDataURL(files[0]) // temporary read data:image/base64

        this.$emit('input', files[0]) // emit to parent if any
      }
    }
  },
  computed: {
    ...mapState('profile', ['profile'])
  }
}
</script>

<style lang="scss" scoped>
.base-image-input {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.active-editing.base-image-input:hover {
  opacity: 0.5;
  background-color: gray;
}

.active-editing.base-image-input {
  opacity: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.active-editing.placeholder {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.preview.placeholder {
  display: none;
}

.inactive-editing .placeholder {
  display: none;
}
.file-input {
  display: none;
}
</style>

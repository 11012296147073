<template lang="pug">
div#version
  p.has-text-centered version 2022.03.27
</template>

<script>
export default {
  name: 'AppVersion'
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

#version {
  color: $text-color;
}
</style>

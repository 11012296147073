<template>
  <div id="about-us">
    <AppNav />

    <template v-if="pageName === 'tech'">
      <AboutTech />
    </template>

    <template v-else-if="pageName === 'team'">
      <AboutTeam />
    </template>

    <template v-else>
      <AboutMotivation />
    </template>
    <AppFooter />
  </div>
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import AboutTeam from '@/components/aboutUs/AboutTeam.vue'
import AboutTech from '@/components/aboutUs/AboutTech.vue'
import AboutMotivation from '@/components/aboutUs/AboutMotivation.vue'
import AppFooter from '@/components/atomic/AppFooter.vue'

let lastPage = ''
export default {
  name: 'AboutUsMain',
  components: {
    AppNav,
    AboutTeam,
    AboutTech,
    AboutMotivation,
    AppFooter
  },

  data() {
    return {
      pageName: lastPage
    }
  },

  beforeRouteEnter(to, from, next) {
    lastPage = to.params.page
    next()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.page != this.pageName) {
      this.pageName = to.params.page
      next()
    } else {
      next()
    }
  }
}
</script>

import axios from 'axios'

const API_ENDPOINT = new URL('/api/profiles', process.env.VUE_APP_DOMAIN).href

class ProfileService {
  create(profileRequest) {
    return axios.post(API_ENDPOINT, profileRequest).then(response => {
      if (response.data.data) {
        return response.data.data
      } else {
        let error = new Error('profiles: cannot create')
        return error
      }
    })
  }

  getProfile(username) {
    return axios.get(API_ENDPOINT + `?username=${username}`).then(response => {
      if (response.data.data) {
        return response.data.data
      } else {
        let error = new Error(
          `profiles: cannot find profile from username: ${username}`
        )
        return error
      }
    })
  }

  updateProfileWithImageMetadata(profile, image) {
    let requestBody = { profile: profile, image: image }
    console.log(requestBody)
    return axios
      .put(API_ENDPOINT + `/${profile.id}`, requestBody)
      .then(response => {
        if (response.data.data) {
          return response.data.data
        } else {
          let error = new Error('profiles: cannot be update with email')
          return error
        }
      })
  }
}

export default new ProfileService()

import { createWebHistory, createRouter } from 'vue-router'
import HomeMain from '@/components/home/HomeMain.vue'
import ProductMain from '@/components/products/ProductMain.vue'
import ProductDetail from '@/components/products/ProductDetail.vue'
import AuthMain from '@/components/auth/AuthMain.vue'
import AdminMain from '@/components/admin/AdminMain.vue'
import AboutUsMain from '@/components/aboutUs/AboutUsMain.vue'
import BlogPost from '@/components/posts/BlogPost.vue'
import InvestorRelation from '@/components/investors/InvestorRelation.vue'
import PitchPresentation from '@/components/investors/PitchPresentation.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeMain
  },
  {
    path: '/admin/login',
    component: AuthMain,
    name: 'auth-login'
  },
  {
    path: '/admin',
    component: AdminMain,
    name: 'admin-main'
  },

  {
    path: '/blogs',
    name: 'blogs',
    component: BlogPost
  },
  {
    path: '/investors',
    name: 'investors',
    component: InvestorRelation
  },
  {
    path: '/pitch/:id',
    name: 'pitch',
    component: PitchPresentation
  },
  {
    path: '/about-us/:page',
    name: 'about-us',
    component: AboutUsMain
  },
  {
    path: '/products',
    name: 'products',
    component: ProductMain
  },
  {
    path: '/products/detail',
    name: 'product-detail',
    component: ProductDetail
  }
]

const router = createRouter({
  history: createWebHistory(),
  hash: false,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: routes
})

export default router

<template>
  <img :src="src" :class="classes" :alt="alt" />
</template>

<script>
export default {
  name: 'ImageData',
  props: ['url', 'mode', 'alt', 'classes'],
  data() {
    return {
      placeholderUrl:
        'https://versions.bulma.io/0.7.0/images/placeholders/1280x960.png',
      src: 'https://versions.bulma.io/0.7.0/images/placeholders/1280x960.png'
    }
  },

  async mounted() {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    let self = this
    xhr.onload = event => {
      event.preventDefault()
      var reader = new FileReader()
      reader.readAsDataURL(xhr.response)
      reader.onloadend = function() {
        const base64data = reader.result
        self.src = base64data
        self.$emit('loaded')
      }
    }
    xhr.open('GET', this.url)
    xhr.send()
  },
  watch: {
    url(newUrl, oldUrl) {
      if (url !== placeholderUrl) {
        fetchImageData(url)
      }
    }
  }
}
</script>

<template>
  <header class="video-header">
    <video
      ref="video"
      src="https://storage.googleapis.com/meganic_assets/looping/loop03.mp4"
      autoplay
      loop
      playsinline
      muted
    ></video>
    <div class="viewport-header">
      <div class="title is-ancestor">
        <h1 v-if="isShownText" class="tile">
          <vue-writer
            :array="texts"
            :typeSpeed="150"
            :iterations="1"
            :delay="2000"
          />
        </h1>
        <div class="tile center-me">
          <router-link
            class="title button is-medium is-rounded navbar-item animate__animated animate__fadeInUp animate__delay-2s"
            to="/about-us/motivation"
          >
            JOIN OUR MISSION
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import VueWriter from 'vue-writer'
export default {
  name: 'HomeTakeToMotivation',
  components: { VueWriter },
  data() {
    return {
      texts: ['WE GROW THE WORLD'],
      isShownText: false
    }
  },
  mounted() {
    this.$loadingOverlay.show()
    const video = this.$refs.video
    video.looping = false
    video.addEventListener(
      'ended',
      function() {
        video.currentTime = 0.1
        video.play()
      },
      false
    )

    video.addEventListener('loadeddata', event => {
      console.log('huu ended')
      this.$loadingOverlay.hide()
      this.isShownText = true
    })
    video.play()
  }
}
</script>

<style lang="scss" scoped>
@mixin coverer {
  width: 100vw;
  height: 105vh;
  position: absolute;
  top: 0;
  left: 0;
}

.video-header {
  position: absolute;
  text-align: center;
  width: 100vw;
  height: 100%;
  &,
  video,
  .viewport-header {
    @include coverer;
  }
  video {
    background: rgba(133, 185, 53, 1);
    object-fit: cover;
  }
  .viewport-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.center-me {
  position: relative;
  top: 45%;
  left: 40%;
}

h1 {
  font-family: 'KoHo', sans-serif;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1vw;
  line-height: 2;
  font-size: 6vw;
  text-align: center;
  span {
    display: block;
    font-size: 10vw;
    letter-spacing: -0.6rem;
  }
}

// mobile
@media only screen and (max-width: 600px) {
  .center-me {
    transform: translate(-40%, 45%);
  }

  h1 {
    font-weight: 900;
    font-size: 1.7rem;
  }
}
</style>

import ProfileService from '@/services/profile.service'
import Profile from '@/models/profile'

export const profile = {
  namespaced: true,
  state: () => ({
    profile: new Profile()
  }),
  actions: {
    create({ commit }, profileRequest) {
      ProfileService.create(profileRequest).then(
        profileResponse => {
          commit('createProfileSuccesfully', profileResponse)
          return Promise.resolve(profileResponse)
        },
        error => {
          commit('createProfileFailure')
          return Promise.reject(error)
        }
      )
    },

    getProfileByUsername({ commit }, username) {
      ProfileService.getProfile(username).then(
        profile => {
          commit('getProfileSuccesfully', profile)
          return Promise.resolve(profile)
        },
        error => {
          commit('getProfileFailure')
          return Promise.reject(error)
        }
      )
    },
    updateProfileWithImageMetadata({ commit }, profileWithImage) {
      ProfileService.updateProfileWithImageMetadata(
        profileWithImage.profile,
        profileWithImage.image
      ).then(
        profileResponse => {
          commit('createProfileSuccesfully', profileResponse)
          return Promise.resolve(profileResponse)
        },
        error => {
          commit('createProfileFailure')
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
    createProfileSuccesfully(state, profile) {
      state.profile = profile
    },
    createProfileFailure(state) {
      state.profile = null
    },
    getProfileSuccesfully(state, profile) {
      if (profile) {
        state.profile = profile
      } else {
        this.resetState()
      }
    },
    getProfileFailure(state) {
      state.profile = null
    }
  },
  getters: {
    getProfile(state) {
      return state.profile
    }
  },
  resetState() {
    this.state.profile = null
    this.state.allProfiles = null
  }
}

<template>
  <div id="home-partnership" class="hero is-fullheight has-background">
    <MqResponsive target="">
      <img
        alt=""
        class="hero-background"
        src="https://storage.googleapis.com/meganic_assets/portrait-partner-banner-bg-long.png"
      />
    </MqResponsive>
    <MqResponsive target="md+">
      <img
        alt=""
        class="hero-background"
        src="https://storage.googleapis.com/meganic_assets/lanscape-partner-banner-bg.png"
      />
    </MqResponsive>

    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-half is-tablet">
            <article data-scroll>
              <h2 class="subtitle">Đặt hàng mẫu & đánh giá chất lượng</h2>
              <h1 class="title branding">
                Trở thành đối tác của<br />Metro Organico
              </h1>
            </article>

            <div class="round-divider"></div>

            <article data-scroll class="bottom-content">
              <h2 class="subtitle is-6">
                Nông nghiệp phải tạo điều kiện cho người trồng trọt có thể sống
                tốt từ nghề của họ mà không phải đánh đổi sức khỏe của người
                tiêu dùng và tương lai của môi trường.
              </h2>
              <h2 class="subtitle is-6">
                Tôn trọng, thân thiện, cởi mở, chuyên nghiệp là những giá trị mà
                Metro Organico hướng tới.
              </h2>
              <br /><br />
              <h1 class="title is-5">
                Hãy gọi cho chúng tôi hôm nay để cùng phát triển bền vững.
              </h1>
            </article>

            <div class="button-group level is-mobile">
              <div class="level-item">
                <button class="button is-primary is-fullwidth">THỬ NGAY</button>
              </div>
              <div class="level-item">
                <button class="button is-light is-fullwidth">LIÊN HỆ</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollOut from 'scroll-out'
import { MqResponsive } from 'vue3-mq'

export default {
  name: 'HomePartnership',
  components: { MqResponsive },
  mounted() {
    this.so = ScrollOut({
      scope: this.$el,
      threshold: 0.1
    })
  },
  destroyed() {
    this.so.teardown()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

.section {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#home-partnership {
  .subtitle,
  .title,
  h2,
  ul,
  li {
    color: $text-inverted-color;
  }

  .branding {
    color: $primary-color;
  }

  .has-background {
    position: relative;
    overflow: hidden;
  }

  .hero-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100vh;
  }

  .border-bottom {
    padding-bottom: 2rem;
    border-bottom: solid 2px #fcfcfc;
  }

  ul {
    margin-left: 3rem;
    list-style-type: disc;
  }

  .round-divider {
    margin: 1rem 0rem 3rem 0rem;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #fcfcfc;
  }
  .button-group {
    padding-top: 2rem;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    .hero-body {
      padding-top: 200px !important;
    }

    .hero-background {
      position: absolute;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 140%;
    }
  }
}
</style>

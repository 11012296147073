function downloadPdf(url, label) {
  const xhr = new XMLHttpRequest()
  xhr.responseType = 'blob'
  xhr.onload = event => {
    event.preventDefault()
    const blob = new Blob([xhr.response], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = label
    link.click()
    URL.revokeObjectURL(link.href)
  }
  xhr.open('GET', url)
  xhr.send()
}

module.exports = { downloadPdf }

import "wc-spinners";

export default class LoadingOverlay {
  constructor() {
    if (document.querySelector("#loading-overlay") !== null) {
      return;
    }
    let node = document.createElement("div");
    node.setAttribute("id", "loading-overlay");
    let spinner = document.createElement("breeding-rhombus-spinner");
    node.appendChild(spinner);

    this.node = node;
    window.document.body.appendChild(this.node);

    this.hide();
  }

  show() {
    this.node.classList.remove("hide");
    this.node.classList.add("show");
  }

  hide() {
    this.node.classList.remove("show");
    this.node.classList.add("hide");
  }
}

class ContributorModel {
  constructor(
    id,
    firstName,
    lastName,
    title,
    qualification,
    hobby,
    location,
    profileImageUrl,
    briefDescription,
    fullDescription,
    quote
  ) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.profileImageUrl = profileImageUrl
    this.title = title
    this.qualification = qualification
    this.hobby = hobby
    this.location = location
    this.briefDescription = briefDescription
    this.fullDescription = fullDescription
    this.quote = quote
  }
}

let mockContributors = [
  new ContributorModel(
    1,
    'Ngan',
    'Nguyen',
    'Founder | CEO',
    'Chemical Engineer (Ph.D./ M.Sc.)',
    'Microchip Engineer | Farming Enthusiastic ',
    'Germany',
    'https://storage.googleapis.com/meganic_assets/contributors/ngan-portrait-square-v2.png',
    'Trưởng nhóm nghiên cứu tại Đại Học Kỹ Thuật Dresden và Viện Max Planck, Đức',
    'Anh Ngàn lấy bằng tiến sĩ công nghệ hóa học, và đã có hơn 10 năm kinh nghiệm phát triển các sản phẩm công nghệ cao tại Hàn Quốc, Phần Lan và Đức. Anh nhận được nhiều giải thưởng khoa học trong và ngoài nước. Chuyên môn của anh bao gồm việc xử lý các bề mặt của vật liệu bán dẫn cho các linh kiện bán dẫn tiên tiến. Từ 2019, anh áp dụng kiến thức này để cùng với các đồng sự phát triển giải pháp thủy canh nano.'
  ),
  new ContributorModel(
    2,
    'Pete',
    'Tran',
    'Founder | CTO',
    'M.Sc. in Computer Science',
    'Entrepreneur | Linguaphile ',
    'USA',
    'https://storage.googleapis.com/meganic_assets/contributors/phuoc-portrait-square.png',
    'Staff Software Engineer',
    'Anh Trần Hữu Phước đã dành gần một thập kỉ làm việc tại Wall Street cũng như Silicon Valley tại những công ty hàng đầu thế giới như AT&T, DIRECTV, Fidelity, WalmartLabs. Nhận thấy Việt Nam còn nhiều tiềm năng để phát triển công nghệ nông nghiệp 4.0, anh cùng cộng sự phát triển kế hoạch lâu dài để đưa nông sản sạch Việt Nam thống lĩnh Đông Nam Á và vươn ra thế giới.',
    'Mình toàn làm những việc khó nhằn, dự án này nhiều người bảo điên rồ nên mình càng quyết tâm làm.'
  ),
  new ContributorModel(
    3,
    'Dominik',
    'P.',
    'Technologist',
    'Polymer Chemist (Ph.D./ M.Sc.) with Creative Thinking ',
    'Aspiring IT-Consultant | Organic Food Lover',
    'Germany',
    'https://storage.googleapis.com/meganic_assets/contributors/dominik-portrait-square.png',
    '',
    'Chuyên gia polymer, có kinh nghiệm nhiều năm tổng hợp các vật liệu nano thân thiện môi trường. Anh đồng thời tham gia dự án với vai trò cố vấn các hoạt động R&D và mô hình kinh doanh ở thị trường châu Âu.'
  ),
  new ContributorModel(
    4,
    'Thuy',
    'Nguyen',
    'Head of R&D',
    'Physical Chemist (M.Sc.) ',
    'Farming Enthusiastic',
    'Vietnam',
    'https://storage.googleapis.com/meganic_assets/contributors/thuy-portrait-square.png',
    'Giảng viên ĐH Khoa Học Tự Nhiên',
    'Là người đi tiên phong ở Việt Nam trong lĩnh vực xử lý hợp chất hữu cơ thiên nhiên, chị Thuỷ liên tục có những nghiên cứu được đăng trên táp chí khoa học trong nước và quốc tế. Bằng nghiên cứu của mình, chị đã giúp cho rau củ của Metro Organico tăng trọng hơn 30% so với các phương pháp thuỷ canh khác.',
    'Giúp mọi người làm nông nghiệp cũng như giúp bố mẹ họ hàng mình ở quê thôi, có gì mà lên web.'
  ),
  new ContributorModel(
    5,
    'Dang',
    'Tran',
    'Founder | CFO',
    'BA in Finance, Accounting & Management | FCCA',
    'Accounting matters, and does Organic vegetables',
    'Vietnam',
    'https://storage.googleapis.com/meganic_assets/contributors/dang-portrait-square.png',
    'FP&A Professional',
    'Đăng Trần là thành viên lâu năm của hiệp hội Kế toán Công chứng Anh Quốc ACCA. Sau một thời gian tích lũy kinh nghiệm tại UK, anh trở về Việt Nam đảm nhận vị trí chuyên viên phân tích tài chính cao cấp mảng dầu khí, tham gia nhiều dự án trong nước và quốc tế. Anh là một trong những nhà đầu tư tiên phong vào Metro Organico từ ngày đầu thành lập. Hiện anh là quản lý tài chính của dự án.'
  )
]

export { ContributorModel, mockContributors }

import axios from "axios";
const DOMAIN = process.env.VUE_APP_DOMAIN;
const UPLOAD_LOCATION_ENPOINT = new URL("api/images/upload-location", DOMAIN)
  .href;
const IMAGES_ENPOINT = new URL("api/images/", DOMAIN).href;
class ImageService {
  constructor() {}

  async uploadImage(fileObject) {
    try {
      const fileName = fileObject.name;
      let uploadResponse = await this.getUploadURI(fileName);
      let putImageToCloudResponse = await this.putImageToCloud(
        fileObject,
        uploadResponse.locationUri
      );
      return putImageToCloudResponse;
    } catch (error) {
      return error;
    }
  }

  getUploadURI(filename) {
    let queryObj = { name: filename };
    return axios
      .get(UPLOAD_LOCATION_ENPOINT, {
        params: queryObj
      })
      .then(response => {
        if (response.data.data) {
          return Promise.resolve(response.data.data);
        } else {
          return Promise.reject(
            new Error(
              `getUploadURI: cannot find profile from username: ${filename}`
            )
          );
        }
      });
  }

  putImageToCloud(imageFile, uri) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        if (this.status >= 200 && this.status < 300) {
          let jsonResponse = JSON.parse(xhr.response);
          resolve(jsonResponse);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function() {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.open("PUT", uri, true);
      xhr.send(imageFile);
    });
  }

  delete(imageName) {
    const DELETE_ENDPOINT = IMAGES_ENPOINT + imageName;
    return axios.delete(DELETE_ENDPOINT);
  }
}

export default new ImageService();

import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import 'animate.css'
import VueSmoothScroll from 'vue3-smooth-scroll'
import { Vue3Mq } from 'vue3-mq'
import store from '@/store/index'
import router from '@/router/router'

require('@/assets/main.scss')

import App from './App.vue'

import {
  faShoppingCart,
  faPiggyBank,
  faShippingFast,
  faShoppingBag,
  faUser,
  faAt,
  faCheck,
  faBuilding,
  faPhoneAlt,
  faLock,
  faEye,
  faGlobe,
  faEnvelope,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

import { faEnvira, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImageData from '@/components/atomic/ImageData.vue'

import LoadingOverlay from '@/loadingOverlay.js'
import i18n from './i18n'

library.add(
  faShippingFast,
  faShoppingCart,
  faPiggyBank,
  faShoppingBag,
  faEnvira,
  faFacebook,
  faUser,
  faAt,
  faCheck,
  faBuilding,
  faPhoneAlt,
  faLock,
  faEye,
  faGlobe,
  faEnvelope,
  faQuestionCircle
)

const app = createApp(App)

app
  .use(router)
  .use(store)
  .use(i18n)
  .use(VueSmoothScroll)
  .use(Vue3Mq)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('image-data', ImageData)

app.config.globalProperties.$loadingOverlay = new LoadingOverlay()

app.mount('#app')

<template>
  <div id="app" class="animate__animated animate__fadeIn animate__fast">
    <AppNav />
    <router-view class="mt-6"> </router-view>
  </div>
</template>

<script>
import AppNav from '@/components/AppNav.vue'
import { mapState } from 'vuex'
export default {
  name: 'App',
  components: {
    AppNav
  },
  data() {
    return {
      refreshInterval: 86400000 // 1 day
    }
  },
  computed: {
    ...mapState('auth', ['status', 'user'])
  },
  methods: {
    refreshFlowStart(username, duration) {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }

      this.timer = setInterval(
        () => {
          if (!this.status.loggedIn) {
            clearInterval(this.timer)
            return
          }
          this.$store
            .dispatch('auth/countDownSilentRefresh', username)
            .then(userResponse => {
              this.refreshInterval =
                Date.parse(userResponse.tokenExpiry) - Date.now()
            })
        },
        duration ? duration : this.refreshInterval
      )
    }
  },
  watch: {
    status(val) {
      if ((val.loggedIn && val.loggedIn == false) || !this.user) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.refreshInterval = Date.parse(this.user.tokenExpiry) - Date.now()
        this.refreshFlowStart(this.user.username, this.refreshInterval)
        this.$store.dispatch('profile/getProfileByUsername', this.user.username)
      }
    }
  }
}
</script>

import axios from 'axios'

const API_URL = 'https://encor.pythonanywhere.com' + '/mo/api/investors'

class PitchService {
  async checkVC(code) {
    try {
      console.log(API_URL)
      const response = await axios.get(`${API_URL}?code.eq=${code}`)
      const { data } = response
      const { items } = data
      if (items.length !== 0) {
        const { name } = items[0]
        return Promise.resolve(name)
      } else {
        return Promise.reject(new Error('Could not find the given code'))
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new PitchService()

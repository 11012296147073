<template>
  <div class="section" id="hydro-introduction">
    <div class="container">
      <article data-scroll class="has-text-centered">
        <h1 class="title is-size-2 is-hand-script">
          Cùng <span style="color: #11998E;">Metro Organico</span> khám phá công
          nghệ nông nghiệp thuỷ canh
        </h1>
        <p>
          Metro Organico mang đến những sản phẩm tươi ngon và chất lượng nhất
          đến tay người tiêu dùng. 
          <ul class="is-size-5 has-text-weight-bold">
            <li>100% công nghệ Châu Âu</li>
            <li>100% hạt giống Hoa Kỳ</li>
            <li>100% trồng tại Việt Nam</li>
          </ul>
           
          
        </p>
        <br >
        <p>Sự hài lòng và sức khỏe của các bạn luôn được đặt lên hàng đầu.</p>
      </article>
      <div class="section">
        <div class="columns">
          <div
            class="column has-text-centered is-flex"
            v-for="content in contents"
            :key="content.id"
          >
            <div class="box">
              <article data-scroll class="card-content fixed-content">
                <h2 class="title is-5">{{ content.title }}</h2>

                
                <span class=" has-text-justified" v-html="content.message"></span>
                
              </article>
              <ZoomingImage
                :img_url="content.image_url"
                :bulma_attributes="bulma_img_style"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollOut from 'scroll-out'
import ZoomingImage from '@/components/utils/ZoomingImage.vue'

export default {
  name: 'HomeHydroIntroduction',
  components: { ZoomingImage },
  
  data() {
    return {
      bulma_img_style: ['is-1by1'],
      contents: [
        {
          id: 1,
          title: 'Công nghệ này là của chúng tôi.',
          message:
            'Hạt giống được cung cấp bởi các đối tác lớn tại Mỹ, sau đó được Metro Organico lựa chọn từng hạt một, nhờ đó đảm bảo tỷ lệ nảy mầm cao*.\
            Chỉ những cây con đạt chất lượng tốt nhất mới được chuyển lên giàn giá thể thủy canh.\
            Dung dịch dinh dưỡng nano, được thiết kế riêng cho hệ thống thủy canh, thành phần hoàn toàn tự nhiên, thân thiện với môi trường.\
            Hệ thống cung cấp dinh dưỡng cho cây được thiết kế tự động, khép kín, dung dịch dinh dưỡng được tuần hoàn và kiểm soát chặt chẽ.\
            Việc thu hoạch được thực hiện nghiêm ngặt, bảo đảm bạn có thể ăn ngay khi vừa được giao.\
             \
            <br>\
            <br>\
            <i><b>*</b> Trong điều kiện phòng thí nghiệm Metro Organico</i>',
          image_url:
            'https://storage.googleapis.com/meganic_assets/hydro-tan-tien.svg'
        },
        {
          id: 2,
          title: 'Sản phẩm này là dành cho bạn',
          message:
            'Chỉ sau khi nhận đơn hàng, nhân viên của Metro Organico mới bắt đầu thu hoạch. Và trong tối đa 2h, rau củ xanh tươi ngon nhất sẽ được chuẩn bị đóng gói và phục vụ tới tay người dùng.\
              Phân bón, hóa chất, thuốc trừ sâu hoàn toàn không nằm trong danh mục từ vựng của chúng tôi. \
              Metro Organico đảm bảo rau củ luôn luôn tươi mới và giữ trọn vẹn chất dinh dưỡng nhất.\
              ',
          image_url:
            'https://storage.googleapis.com/meganic_assets/hydro-than-thien.svg'
        },
        {
          id: 3,
          title: 'Và cùng nghĩ đến hành tinh này.',
          message:
            'Với trồng rau truyền thống – 5% nước tưới là dành cho cây trồng, 95% bị lãng phí. \
            Với thuỷ canh - 80% nước tưới là dành cho cây trồng, 20% bị phung phí.\
            Với thủy canh nano tại Metro Organico - 100% nước tưới là dành cho sản phẩm của bạn, \
            Metro Organico không chấp nhận việc lãng phí nước sạch.',
          image_url:
            'https://storage.googleapis.com/meganic_assets/hydro-tiet-kiem-nuoc.svg'
        }
      ]
    }
  },

  mounted() {
    let self = this
    self.so = ScrollOut({
      scope: this.$el
    })
  },
  destroyed() {
    self.so.teardown()
  }
}
</script>

<style lang="scss" scoped>
.fixed-content {
  min-height: 80vh;
}
#hydro-introduction {
  line-height: 2;
  background: rgb(252, 252, 252);
  background: linear-gradient(
    180deg,
    rgba(252, 252, 252, 1) 0%,
    rgba(47, 128, 237, 1) 100%
  );
}

// mobile
@media only screen and (max-width: 600px) {
  .fixed-content {
  min-height: auto;
}
}
</style>

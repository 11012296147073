<template>
  <div class="section" id="login">
    <div class="container">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <div class="section">
            <figure class="image logo">
              <img
                src="https://storage.googleapis.com/meganic_assets/footer-logo-full.svg"
                alt="Metro Organico, a fresh start for a young new generation"
              />
            </figure>
          </div>
          <transition name="fade">
            <div v-if="currentTab === tabs.LOGIN">
              <auth-login @clicked="toggleForm"></auth-login>
            </div>
            <!-- Register  -->
            <div v-else-if="currentTab === tabs.REGISTER">
              <auth-register @clicked="toggleForm"></auth-register>
            </div>

            <!-- Forget password  -->
            <div v-else>
              <div class="field">
                <p class="control has-icons-left has-icons-right">
                  <input
                    class="input outlined"
                    type="email"
                    placeholder="Email"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="at" />
                  </span>
                  <span class="icon is-small is-right">
                    <font-awesome-icon icon="check" />
                  </span>
                </p>
              </div>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    class="input"
                    type="password"
                    placeholder="New password"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="lock" />
                  </span>
                </p>
              </div>

              <div class="field">
                <p class="control has-icons-left">
                  <input
                    class="input"
                    type="password"
                    placeholder="Confirm password"
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="check" />
                  </span>
                </p>
              </div>
              <div class="field">
                <p class="control">
                  <button
                    :class="{ focused: isActive }"
                    class="button is-fullwidth is-success"
                  >
                    Reset
                  </button>
                </p>
              </div>

              <div class=" divider has-text-centered">
                <span class="dash"></span>
                <p class="py-5">OR</p>
                <span class="dash"></span>
              </div>
              <div class="field">
                <p class="control">
                  <button
                    @click="toggleForm(tabs.LOGIN)"
                    :class="{ focused: isActive }"
                    class="button is-fullwidth is-success is-outlined"
                  >
                    Login
                  </button>
                </p>
              </div>

              <div class="field">
                <p class="control">
                  <button
                    @click="toggleForm(tabs.REGISTER)"
                    :class="{ focused: isActive }"
                    class="button is-fullwidth is-success is-outlined"
                  >
                    Register
                  </button>
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthLogin from '@/components/auth/AuthLogin.vue'
import AuthRegister from '@/components/auth/AuthRegister.vue'
import TABS from '@/components/auth/tabs'

export default {
  name: 'AuthMain',
  components: {
    AuthLogin,
    AuthRegister
  },
  data() {
    return {
      currentTab: TABS.LOGIN,
      isActive: false,
      tabs: TABS
    }
  },
  methods: {
    toggleForm(tab) {
      this.currentTab = tab
      this.isActive = false
    },

    setActive(val) {
      this.isActive = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

#login {
  min-height: 100vh;
  background-color: $text-color;
  color: $text-inverted-color;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider p {
  padding: 5px 20px;
  color: #888;
}
.dash {
  border: 1px solid #888;
  width: 150px;
}

.logo {
  width: 320px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .logo {
    width: 200px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

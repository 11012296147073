<template>
  <figure class="img-hover-zoom">
    <img :src="img_url" :class="bulma_attributes" />
  </figure>
</template>

<script>
export default {
  name: "ZoomingImageFixed",
  props: {
    img_url: {
      type: String,
      required: true
    },
    bulma_attributes: {
      type: Array,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.img-hover-zoom {
  overflow: hidden;
}

.img-hover-zoom img {
  width: 300px;
  height: 300px;
  object-fit: contain;
  transition: transform 0.2s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.1);
}
</style>

<template>
  <div>
    <div class="container top-spacing pb-6">
      <div class="block title is-2 has-text-centered is-hand-script">
        Sản phẩm chính
      </div>
      <div class="columns has-text-centered">
        <div class="column auto" v-for="content in contents" :key="content.id">
          <div class="card-content">
            <ZoomingImageFixed :img_url="content.image_url" />
            <div class="block">
              <article data-scroll>
                <h2 class="subtitle is-5">{{ content.title }}</h2>
                <p class="title is-5">{{ content.price }}</p>
                <p class="">{{ content.desc }}</p>
              </article>
            </div>

            <router-link
              class="button is-primary is-outlined"
              :to="content.nav_link"
              @click.native="didClick"
            >
              XEM SẢN PHẨM
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import ZoomingImageFixed from '@/components/utils/ZoomingImageFixed.vue'
import AppFooter from '@/components/atomic/AppFooter.vue'

export default {
  name: 'ProductMain',
  components: { ZoomingImageFixed, AppFooter },
  data() {
    return {
      contents: [
        {
          id: 1,
          type: 'veggies',
          title: 'Kale Xanh',
          price: '50.000 ₫ / Cây',
          desc: 'Cây cải xoăn thu hoạch trong ngày, xanh và sạch',
          image_url:
            'https://storage.googleapis.com/meganic_assets/products/veg-kale-white-bg.png',
          nav_link: '/products/detail?sp=kale&types=veggies'
        },
        {
          id: 2,
          type: 'drinks',
          title: 'Nước Ép Kale-Táo',
          price: '40.000 ₫ / Chai 200ml',
          desc: 'Nước ép Kale trái cây tràn đầy dinh dưỡng',
          image_url:
            'https://storage.googleapis.com/meganic_assets/products/drink-kale-bottle.png',
          nav_link: '/products/detail?sp=kale-apple&type=drinks'
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-content {
  min-height: 400px;
}
.top-spacing {
  padding-top: 4rem;
}
</style>

<template>
  <div>
    <div class="field">
      <p class="control has-icons-left has-icons-right">
        <input
          class="input"
          type="user"
          placeholder="username"
          v-model="user.username"
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="user" />
        </span>
        <span class="icon is-small is-right">
          <font-awesome-icon icon="check" />
        </span>
      </p>
    </div>

    <div class="field has-addons">
      <p class="control is-expanded has-icons-left">
        <input
          class="input"
          :type="textOrPassword"
          placeholder="Password"
          v-model="user.password"
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="lock" />
        </span>
      </p>
      <p class="control">
        <button
          class="button is-ghost"
          @click="
            () => {
              this.textOrPassword =
                this.textOrPassword === 'password' ? 'text' : 'password'
            }
          "
        >
          <font-awesome-icon icon="eye" />
        </button>
      </p>
    </div>

    <div class="divider">
      <p>Optional</p>
    </div>

    <div class="field">
      <p class="control has-icons-left has-icons-right">
        <input
          class="input"
          type="user"
          placeholder="email"
          v-model="profile.email"
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="fa-at" />
        </span>
        <span class="icon is-small is-right">
          <font-awesome-icon icon="check" />
        </span>
      </p>
    </div>

    <div class="field">
      <p class="control">
        <input
          class="input"
          type="user"
          placeholder="First name"
          v-model="profile.firstName"
        />
      </p>
    </div>

    <div class="field">
      <p class="control">
        <input
          class="input"
          type="user"
          placeholder="Last name"
          v-model="profile.lastName"
        />
      </p>
    </div>

    <div class="field">
      <p class="control">
        <button
          :class="{ focused: isActive }"
          class="button is-fullwidth is-success"
          @click="handleRegister"
        >
          Register
        </button>
      </p>
    </div>

    <div class=" divider has-text-centered">
      <span class="dash"></span>
      <p class="py-5">OR</p>
      <span class="dash"></span>
    </div>
    <div class="field">
      <p class="control">
        <button
          :class="{ focused: isActive }"
          class="button is-fullwidth is-success is-outlined"
          @click="emitChangeForm(tabs.LOGIN)"
        >
          Login
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import User from '@/models/user'
import Profile from '@/models/profile'
import TABS from '@/components/auth/tabs'
import _ from 'lodash'
export default {
  name: 'AuthRegister',
  data() {
    return {
      user: new User(),
      profile: new Profile(),
      isActive: false,
      tabs: TABS,
      textOrPassword: 'password',
      message: ''
    }
  },
  methods: {
    emitChangeForm(tab) {
      this.$emit('clicked', tab)
    },
    handleRegister() {
      if (this.user.username && this.user.password) {
        this.$loadingOverlay.show()
        this.$store
          .dispatch('auth/register', this.user)
          .then(
            data => {
              if (data.token && data.username) {
                let body = {
                  username: data.username,
                  profile: _.cloneDeep(this.profile)
                }
                this.$store
                  .dispatch('profile/create', body)
                  .then(profileResponse => {
                    if (profileResponse) {
                      this.$loadingOverlay.hide()
                      this.$router.push('/admin')
                    }
                  })
                  .finally(() => this.$loadingOverlay.hide())
              }
            },
            error => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString()
            }
          )
          .finally(() => this.$loadingOverlay.hide())
      } else {
        alert('Xin hãy điền thông tin đầy đủ')
      }
    }
  }
}
</script>

<template>
  <div class="section ">
    <div class="container">
      <p class="content is-size-5 block">
        Metro Organico là một tập hợp những nhà khoa học và kỹ sư trẻ được lập
        ra với mục đích giảm thiểu tác hại môi trường trong việc khai thác nông
        nghiệp.
        <br />
        Với những thay đổi khốc nghiệt của thiên nhiên và sự thiếu trách nhiệm
        trong việc khai thác nguồn nước thượng nguồn của nước bạn, đất nước
        chúng ta lại một lần nữa cần phát triển những giải pháp khoa học để
        tránh phụ thuộc vào nguồn tài nguyên thiên nhiên khan hiếm.
        <br />
        Thuỷ canh là một phương pháp khai thác nông nghiệp tiên tiến đã được các
        quốc gia phát triển áp dụng và gặt hái được nhiều thành công.
        <br />
        Metro Organico tin rằng giải pháp công nghệ, và mô hình kinh doanh của
        chúng tôi không chỉ giảm thiểu tác động đến môi trường, mà còn tạo ra
        nền nông nghiệp 4.0 với năng suất vượt trội.
        <br />
        <br />
        Chúng tôi hiểu rằng các bạn sẽ đồng hành cùng chúng tôi trên con đường
        chinh phục mục tiêu này.
      </p>

      <h1 class="subtitle is-size-4 block">
        Hãy để lại lời nhắn cho
        <span class="is-hand-script is-size-3" style="color: #11998E;">
          Metro Organico</span
        >
        các bạn nhé!
      </h1>
    </div>

    <div class="section">
      <div class="container">
        <!-- <div class="field is-horizontal">
          <div class="field-label is-normal is-left">
            <label class="label is-align-items-flex-start">Tên</label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control is-expanded has-icons-left">
                <input class="input" type="text" placeholder="Adam Smith" />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="user" />
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control is-expanded has-icons-left has-icons-right">
                <input
                  class="input"
                  type="email"
                  placeholder="adam@smith.com"
                  value=""
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="at" />
                </span>
                <span class="icon is-small is-right">
                  <font-awesome-icon icon="check" />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Nội dung</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <textarea
                  class="textarea"
                  placeholder="Hãy cùng hợp tác với nhau."
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <p class="subtitle spacer level-item has-text-centered">OR</p> -->

        <div class="field is-horizontal">
          <div class="field-label is-normal is-left">
            <label class="label is-align-items-flex-start"
              >Nhập mã liên hệ
              <span
                class="icon is-small is-right has-tooltip-arrow  has-tooltipl-multiline"
                data-tooltip="Mã được tạo riêng &#10; cho các bạn khi chúng tôi &#10; xác nhận nhận email phản hồi"
              >
                <font-awesome-icon icon="circle-question" /> </span
            ></label>
          </div>
          <div class="field-body">
            <div class="field">
              <p class="control is-expanded has-icons-left">
                <input
                  class="input"
                  type="text"
                  placeholder="Si28x2X8htbp8WxYy9ztJQ"
                  v-model="code"
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="lock" />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="field is-horizontal">
          <div class="field-label">
            <!-- Left empty for spacing -->
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <button
                  class="button is-primary is-outlined is-fullwidth center-me"
                  @click="submitCode()"
                >
                  Xác nhận
                </button>

                <p v-if="showError" style="color: red;">
                  Sorry, we don't recognize your code
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container"></div>
    </div>
  </div>
</template>

<script>
import PitchService from '@/services/pitch.service.js'
export default {
  name: 'InvestorRelation',
  data() {
    return {
      code: '',
      showError: false
    }
  },
  methods: {
    async submitCode() {
      if (this.code.length === 0) {
        return
      }
      try {
        const vc_name = await PitchService.checkVC(this.code)
        if (vc_name) {
          this.showError = false
          this.$router.push({ path: `/pitch/${this.code}` })
        } else {
          this.showError = true
        }
      } catch (err) {
        this.showError = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.center-me {
  margin: auto;
  width: 100%;
}
.spacer {
  padding: 1rem;
}
</style>

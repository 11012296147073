// Authencication service

import axios from "axios";

const API_URL = process.env.VUE_APP_DOMAIN;
const LOGIN_URL = new URL("api/auth/identity/callback", API_URL).href;
const REFRESH_URL = new URL("api/auth/refresh", API_URL).href;
const STORAGE_USER = "user";

class AuthService {
  login(user) {
    return axios
      .post(LOGIN_URL, {
        user: {
          username: user.username,
          password: user.password
        }
      })
      .then(response => {
        if (response.data.data.token) {
          localStorage.setItem(
            STORAGE_USER,
            JSON.stringify(response.data.data)
          );
        }
        return response.data.data;
      });
  }

  logout() {
    localStorage.removeItem(STORAGE_USER);
  }

  register(user) {
    const REGISTER_URL = new URL("api/users", API_URL).href;
    return axios
      .post(REGISTER_URL, {
        user: {
          username: user.username,
          password: user.password
        }
      })
      .then(response => {
        if (response.data.data.token) {
          localStorage.setItem(
            STORAGE_USER,
            JSON.stringify(response.data.data)
          );
        }
        return response.data.data;
      });
  }

  refresh(username) {
    return axios.post(REFRESH_URL, { username: username }).then(
      response => {
        return response.data.data;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
}

export default new AuthService();

<template>
  <div class="">
    <section class="section">
      <nav class="breadcrumb pl-4" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link class="navbar-item" to="/">
              Trang chủ
            </router-link>
          </li>
          <li>
            <router-link class="navbar-item" to="/products">
              Sản phẩm
            </router-link>
          </li>
          <li class="is-active">
            <router-link :to="$route.fullPath">
              {{ current_sp }}
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="container pt-6">
        <div class="columns is-centered">
          <div class="column is-half" v-if="current_item">
            <h1 class="title has-text-centered">{{ current_item.title }}</h1>
            <h2 class="subtitle is-5 has-text-centered">
              {{ current_item.subtitle }}
            </h2>

            <figure>
              <img
                :src="current_item.images.main.url"
                :alt="current_item.images.main.alt"
              />
            </figure>
            <h2 class="has-text-centered subtitle is-6">
              <b
                ><i>{{ current_item.images.main.caption }}</i></b
              >
            </h2>

            <div class="section">
              <div
                class="pb-6"
                v-for="post in current_item.posts"
                :key="post.id"
              >
                <h2 class="title is-6">{{ post.title }}</h2>
                <div
                  class="block"
                  v-for="(text, index) in post.texts"
                  :key="index"
                >
                  <p>{{ text }}</p>
                </div>
                <div class="block" v-if="post.image.url">
                  <figure>
                    <img :src="post.image.url" :alt="post.image.alt" />
                  </figure>
                  <h2 class="has-text-centered subtitle is-7">
                    <b
                      ><i>{{ post.image.caption }}</i></b
                    >
                  </h2>
                </div>
              </div>
            </div>
            <div class="section">
              <h2 class="title is-6">{{ current_item.conclusion.title }}</h2>
              <p class="">{{ current_item.conclusion.text }}</p>
              <p></p>
            </div>
          </div>
          <div class="section has-text-centered" v-else>
            <ZoomingImageFixed
              img_url="https://storage.googleapis.com/meganic_assets/404.png"
            />

            <h1 class="block subtitle is-5">
              Chúng tôi không tìm thấy thông tin về sản phẩm
              <p class="title is-3">{{ current_sp }}</p>
            </h1>

            <router-link
              class="block button is-primary is-outlined"
              to="/products"
            >
              XEM SẢN PHẨM KHÁC
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from '@/components/atomic/AppFooter.vue'
import ZoomingImageFixed from '@/components/utils/ZoomingImageFixed.vue'
let lastPage = ''
export default {
  name: 'ProductDetail',
  components: { ZoomingImageFixed, AppFooter },

  data() {
    return {
      pageName: lastPage,
      items: [
        {
          id: 0,
          name: 'kale',
          type: 'veggies',
          title: 'Cải xoăn - Kale',
          subtitle: 'Siêu thực phẩm cho mọi độ tuổi',
          conclusion: {
            title: 'Hãy là người tiêu dùng thông thái',
            text:
              'Cải Kale tuy là thực phẩm giàu chất dinh dưỡng tốt cho sức khỏe, nhưng khi ăn cần có liều lượng thích hợp. Nếu ăn cải Kale quá nhiều và liên tục thì sẽ có ảnh hưởng xấu đến sức khỏe. Vì vậy, hãy tìm hiểu kỹ về loại rau này trước khi ăn và sử dụng có chừng mực để đảm bảo sức khỏe nhé!'
          },

          images: {
            main: {
              url:
                'https://storage.googleapis.com/meganic_assets/products/veg-kale.png',
              caption: 'Cải xoăn - Vua của các loại rau',
              alt: 'veg-kale'
            },
            gallery: []
          },

          posts: [
            {
              id: 0,
              title: 'Tác dụng của cây cải xoăn',
              texts: [
                'Hàm lượng calorie và chất béo trong cải xoăn kale rất thấp. Tuy vậy, phần lớn chất béo trong đó lại là axit alpha linolenic, một loại axit béo omega-3 mang đến nhiều ích lợi sức khỏe.',
                'Ngoài ra, loại rau cải này còn chứa các hoạt chất có tính chống oxy hóa cao, nhờ đó góp phần tăng cường sức khỏe và phòng ngừa các khối u ác tính hình thành.',
                'Một nghiên cứu đã hé lộ rằng rau cải xoăn có đặc tính chống ung thư cao, nhờ chứa nhiều hợp chất glucosinat. Hợp chất này có thể giúp hạn chế phát triển các khối u cũng như ngăn chặn các enzyme liên quan đến ung thư.',
                'Xương của bạn sẽ chắc khỏe hơn nhờ lượng canxi trong cải Kale còn nhiều hơn trong sữa. Axit béo omega-3 tốt cho não bộ, kháng viêm tốt.',
                'Ngoài ra cải Kale còn hỗ trợ giảm cân hiệu quả, làm đẹp cho da, tóc hiệu quả bởi hàm lượng vitamin C cao, giúp cơ thể sản sinh ra nhiều collagen.'
              ],
              image: {
                id: 0,
                alt: 'inforgraphic',
                url:
                  'https://storage.googleapis.com/meganic_assets/posts/kale/inforgraphic.jpeg',
                caption: 'Dễ dàng chế biến cải xoăn thành bữa ăn dinh dưỡng.'
              }
            },
            {
              id: 1,
              title: 'Thế nhưng những gì tốt quá cũng không nên',
              texts: [
                'Hàm lượng vitamin K trong cải Kale cao nên chúng thúc đẩy quá trình đông máu diễn ra tốt hơn, vì vậy những bệnh nhân đang sử dụng thuốc làm loãng máu như bệnh nhân bị đột quỵ, thiếu máu cục bộ cần tham khảo ý kiến của bác sĩ trước khi sử dụng cải Kale.',
                'cải xoăn còn chứa hàm lượng vitamin C rất cao, còn cao hơn cả vitamin C có trong quả cam, 1 quả cam vừa tay có chứa khoảng 70mg vitamin C trong khi đó hàm lượng này trong 1 chén cải xoăn nhỏ có thể lên tới 80mg.'
              ],
              image: {
                id: 0,
                alt: 'inforgraphic',
                url:
                  'https://storage.googleapis.com/meganic_assets/posts/kale/kale-top-view.jpeg',
                caption: 'Kale rất giàu vitamin K and vitamin C'
              }
            }
          ],
          recipes: [
            {
              ingredients: [{}]
            }
          ]
        },
        {
          id: 1,
          name: 'kale-apple',
          type: 'drinks',
          title: 'Nước ép Kale Trái Cây',
          subtitle: 'Đồ uống tiện lợi lại rất giàu dinh dưỡng',
          conclusion: {
            title: 'Chỉ cần 5 phút là bạn đã sẵn sàng.',
            text:
              "Cải xoăn không chỉ dễ dàng nấu thành các món ăn hấp dẫn, lại còn siêu tiện lợi với 1 nút bấm. 'Xoay Xoay Xoay', thế là đã có trên tay 1 ly sinh tố cực ngon, cực bổ"
          },

          images: {
            main: {
              url:
                'https://storage.googleapis.com/meganic_assets/products/drink-kale-bottle.png',
              caption:
                'Ngại làm - Metro Organico đã có sẵn sản phẩm đóng chai.',
              alt: 'veg-kale'
            },
            gallery: []
          },

          posts: [
            {
              id: 0,
              title:
                'Chán ngấy các món ăn với kale, thử ngay ly sinh tố Kale trái cây!',
              texts: [
                'Bạn có thể cho rau cải xoăn vào xay chung với các loại trái cây khác để tạo thành một món sinh tố thơm ngon và tốt cho sức khỏe. Ngoài ra, nước ép của loại cải này cũng là một sự lựa chọn tuyệt vời mà bạn có thể thử, vừa tốt cho sức khỏe lại vừa đẹp da.',
                'Ăn 1 trái táo mỗi ngày, dánh lùi béo phì. Và cải kale thì chứa một lượng lớn chất xơ, giúp việc thải độc thuận lợi hơn. Metro Organico kết hợp kale, táo và thơm để cho ra một món sinh tố tuyệt vời cho những người tập thể thao cũng như cải thiện làn da rất hiệu quả'
              ],
              image: {
                id: 0,
                alt: 'drink',
                url:
                  'https://storage.googleapis.com/meganic_assets/posts/kale/drink-kale.jpeg',
                caption: 'Có ngay ly sinh tố kale trên tay'
              }
            }
          ],
          recipes: [
            {
              ingredients: [{}]
            }
          ]
        }
      ]
    }
  },

  computed: {
    current_item: function() {
      return this.items.find(item => item.name === this.$route.query.sp)
    },
    current_sp: function() {
      return this.$route.query.sp
    }
  },

  beforeRouteEnter(to, from, next) {
    lastPage = to.params.page
    next()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.page != this.pageName) {
      this.pageName = to.params.page
      next()
    } else {
      next()
    }
  }
}
</script>

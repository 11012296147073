<template>
  <div class="section">
    <div class="container">
      <div v-if="isAllowed" class="section has-text-centered ">
        <ZoomingImageFixed
          img_url="https://storage.googleapis.com/meganic_assets/pitch/green-city.png"
        />

        <h1 class="block title is-3">Thank you {{ vc_name }}!</h1>
        <br />
        <br />
        <h3 class="block subtitle is-5">
          We're glad that you want to help us building a better future.
        </h3>

        <a
          class="button is-primary is-outlined"
          :href="url"
          @click.prevent="downloadItem()"
        >
          Download our Pitchdeck
        </a>
      </div>
      <div v-else class="section has-text-centered ">
        <ZoomingImageFixed
          img_url="https://storage.googleapis.com/meganic_assets/404.png"
        />

        <h1 class="block subtitle is-5">
          We're glad that you want to join.
          <br />
          Please contact us for entry code to download our presentaion.
        </h1>

        <router-link
          class="block button is-primary is-outlined"
          to="/investors"
        >
          Leave a message
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PitchService from '@/services/pitch.service.js'
import { downloadPdf } from '@/plugins/helper.js'
import ZoomingImageFixed from '@/components/utils/ZoomingImageFixed.vue'
export default {
  name: 'PitchPresentation',
  components: { ZoomingImageFixed },
  data() {
    return {
      vc_name: '',
      isAllowed: true,
      label: 'Metro-Organico-pitch',
      url:
        'https://storage.googleapis.com/meganic_assets/pitch/Metro-Organico-pitch.pdf'
    }
  },
  async mounted() {
    const code = this.$route.params.id
    try {
      this.vc_name = await PitchService.checkVC(code)
      this.isAllowed = true
    } catch (err) {
      this.isAllowed = false
    }
  },
  methods: {
    downloadItem() {
      downloadPdf(this.url, this.label)
    }
  }
}
</script>

<style lang="scss" scoped>
.center-me {
  margin: auto;
}
</style>

<template>
  <div id="home-faqs" class="section">
    <div class="container">
      <h1 class="title is-size-2 is-hand-script has-text-centered">FAQs</h1>
      <accordion-option
        id="home-faqs"
        :content="contents"
        multiple="multiple"
      ></accordion-option>
    </div>
  </div>
</template>

<script>
import AccordionOption from '@/components/utils/AccordionOption.vue'

const faqsContents = [
  {
    id: 0,
    active: false,
    title:
      'Tại sao rau củ từ Metro Organico lại phát triển to hơn các cửa hàng khác, các bạn có dùng chất kích thích tăng trưởng không?',
    details: `
      <p>Chúng tôi không dùng chất kích thích cho sản phẩm của Metro Organico.</p> 
      <p>Công nghệ thuỷ canh đảm bảo cây hấp thụ 100% dưỡng chất và tránh được sự tàn phá của sâu bọ.</p>
      <p>Một cây khoẻ mạnh không bệnh tật sẽ có điều kiện phát triển vượt bậc.</p>
    `
  },
  {
    id: 1,
    active: false,
    title: 'Cách bảo quản rau củ từ Metro Organico',
    details: `
      <p>Quý khách có thể để rau trong môi trường tự nhiên từ 2-4 ngày, hoạc 12-14 ngày trong tủ lạnh.</p>
      <p>Rau củ từ Metro Organico tiếp tục dùng dưỡng chất từ bộ rễ để phát triển sau khi hàng đã đến tay quý khách</p>
    `
  },
  {
    id: 2,
    active: false,
    title: 'Vì sao nhiều sản phẩm bán theo đơn vị từng cây mà không theo gram?',
    details: `
      <p>Để dảm bảo độ tươi ngon và bảo quản tự nhiên nhất, cây thuỷ canh thường được bán kèm bộ rễ.</p>
      <p>Như vậy mỗi đơn hàng sẽ tránh được tình trạng thiếu cân so với thục tế.</p>
    `
  },
  {
    id: 3,
    active: false,
    title: 'Làm sao để tái chế chai thuỷ tinh?',
    details: `
      <p>Các bạn có thể đổi 5 chai thuỷ tinh từ Metro Organico để lấy 1 phần nước ép cùng dung tích</p>
    `
  },
  {
    id: 4,
    active: false,
    title: `Có thể kiểm tra trạng thái đơn hàng không?`,
    details: `
      <p>Với mỗi đơn hàng, xin vui lòng để lại số điện thoại, chúng tôi sẽ liên hệ cho bạn về trạng thái đơn hàng!</p>
    `
  }
]

export default {
  name: 'HomeFaqs',
  data() {
    return {
      contents: faqsContents
    }
  },
  components: { AccordionOption }
}
</script>

<template>
  <div class="section">
    <div class="section">
      <article data-scroll class="has-text-centered">
        <h1 class="title is-size-2 is-hand-script">Danh mục sản phẩm</h1>
      </article>
    </div>

    <div class="container">
      <div class="columns has-text-centered">
        <div
          class="column is-half"
          v-for="content in contents"
          :key="content.id"
        >
          <div class="fixed-image">
            <ZoomingImage :img_url="content.image_url" />
          </div>

          <div class="section product-content">
            <article data-scroll>
              <h2 class="title is-5">{{ content.title }}</h2>
              <p class="top-spacing"><span v-html="content.message"></span></p>
            </article>
          </div>

          <router-link
            class="button is-primary is-outlined is-fullwidth"
            to="/products"
          >
            XEM SẢN PHẨM
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZoomingImage from '@/components/utils/ZoomingImage.vue'
import ScrollOut from 'scroll-out'

export default {
  name: 'HomeProductListing',
  components: { ZoomingImage },
  data() {
    return {
      contents: [
        {
          id: 1,
          title: 'Rau củ tươi xanh',
          message:
            '<div>\
            <p>Mỗi đơn hàng đều được tặng kèm công thức chế biến và những tips bảo quản hữu ích. \
            Mỗi cây rau đều được giao kèm giá thể chứa hạt dinh dưỡng, nên bạn cũng có thể tưới nước hàng ngày lên cây để kéo dài thời gian sử dụng mà thậm chí không cần phải cho vào tủ lạnh.\
            </p>\
            <br><br>\
            <p>Bạn có thể rửa sơ rau củ với nước trước khi ăn, hoặc ăn trực tiếp. Nhưng tại gia đình Metro Organico, chúng tôi ăn ngay khi rau còn trồng trên giàn!</p>\
            </div>',
          image_url:
            'https://storage.googleapis.com/meganic_assets/sp-rau-cu-banner.png',
          nav_link: ''
        },
        {
          id: 2,
          title: 'Cung cấp giải pháp nông nghiệp đô thị',
          message: `<div>\
          Metro Organico cung cấp giải pháp được thiết kế riêng cho bạn. 
          <br> <br>
          <h1>Vui lòng liên hệ để được tư vấn 1 cách tốt nhất.</h1>
          </div>`,
          image_url:
            'https://storage.googleapis.com/meganic_assets/products/chuyen-giao-cong-nghe.jpg',
          nav_link: ''
        }
      ]
    }
  },

  mounted() {
    this.so = ScrollOut({
      scope: this.$el
    })
  },

  destroyed() {
    this.so.teardown()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

.fixed-image {
  height: 40vh;
}
.product-content {
  min-height: 300px;
}

.border-button {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: $supplement-color solid 2px;
  border-radius: 2px;
  width: 100%;
  min-height: 40px;
  background-color: transparent;
}

.top-spacing {
  min-height: 120px;
}

.column .section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

article p {
  text-align: justify;
  text-justify: inter-word;
}

@media only screen and (max-width: 720px) {
  .column {
    padding-top: 5rem;
  }
}
</style>

<template>
  <section id="about-team">
    <div class="section jumbotron">
      <div class="hero is-medium">
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column">
                <article class="py-4">
                  <h1 class="title is-size-1">
                    {{ $t('about.headline_about') }}
                  </h1>

                  <p class="is-size-4 has-text-justified has-text-weight-bold">
                    {{ $t('about.content_about_01') }}
                  </p>
                  <br />
                  <p class="is-size-4 has-text-justified has-text-weight-bold">
                    {{ $t('about.content_about_02') }}
                  </p>
                </article>
                <br />
                <br />
              </div>
              <div class="column is-4">
                <figure class="image">
                  <image-data
                    url="https://storage.googleapis.com/meganic_assets/about-team-online-shopping-phone.svg"
                    @loaded="onImageLoad"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="section">
          <h1 class="title is-1 py-5 has-text-centered">Đội ngũ quản lý</h1>
        </div>

        <swiper
          class="mySwiper"
          :slidesPerView="
            this.mq.current === 'xs' || this.mq.current === 'sm'
              ? 1
              : this.mq.current === 'md'
              ? 2
              : 3
          "
          :spaceBetween="30"
          :slidesPerGroup="1"
          :loop="true"
          :loopFillGroupWithBlank="true"
          :pagination="{
            clickable: true
          }"
          :navigation="true"
          :modules="modules"
        >
          <swiper-slide v-for="user in userList" :key="user.id">
            <AboutPersonalCard
              :firstName="user.firstName"
              :lastName="user.lastName"
              :title="user.title"
              :qualification="user.qualification"
              :hobby="user.hobby"
              :location="user.location"
              :profileImageUrl="user.profileImageUrl"
              :briefDescription="user.briefDescription"
              :fullDescription="user.fullDescription"
              :quote="user.quote"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Pagination, Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'

import AboutPersonalCard from '@/components/aboutUs/AboutPersonalCard.vue'
import { mockContributors, ContributorModel } from '@/models/contributor.js'

export default {
  name: 'AboutTeam',
  title: 'Responsive breakpoints',
  inject: ['mq'],
  components: {
    Swiper,
    SwiperSlide,
    AboutPersonalCard
  },
  setup() {
    return {
      modules: [Pagination, Navigation]
    }
  },
  mounted() {
    this.$loadingOverlay.show()
  },
  data() {
    return {
      userList: mockContributors
    }
  },
  methods: {
    onImageLoad: function() {
      this.$loadingOverlay.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

#about-team {
  color: $text-color;
  background-color: $text-inverted-color;
}

.jumbotron.section {
  background: rgb(87, 115, 215);
  background: linear-gradient(
    45deg,
    rgba(87, 115, 215, 1) 0%,
    rgba(55, 251, 216, 1) 100%
  );
}

.hero .subtitle,
.hero .title {
  color: $text-inverted-color;
}

.swiper-button-prev {
  --swiper-navigation-size: 10px;
}

.swiper-button-next {
  width: 10px;
  height: 10px;
  --swiper-navigation-size: 10px;
}

@media (min-width: 720px) {
  .hero p {
    margin-right: 5rem !important;
  }
}
</style>

import ImageMetadata from "@/models/image.metadata";
export default class Profile {
  constructor(firstName, lastName, email, phoneNumber, image) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.image = image ? image : new ImageMetadata();
  }
}

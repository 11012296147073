<template>
  <div id="about-tech">
    <div class="section jumbotron">
      <div class="hero is-medium">
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column">
                <article class="py-4">
                  <h1 class="subtitle">Thuỷ canh 4.0</h1>
                  <h1 class="title is-size-1">
                    Nông nghiệp & tự động hoá.
                  </h1>

                  <p class="is-size-4 has-text-justified has-text-weight-bold">
                    Với công nghệ thuỷ canh, các loại sâu bệnh thường gặp bằng
                    nuôi trồng thông thường hoàn toàn được loại bỏ. Khi cây
                    trồng không phải chống chọi sự tấn công từ sâu rầy, 100%
                    dưỡng chất được dành cho sự phát triển của cây. Giờ dây,
                    Metro Organico giới thiệu công nghệ thủy canh nano. Metro
                    Organico phát triển các hạt nano siêu nhỏ có nguồn gốc tự
                    nhiên, chiết suất từ các sản phẩm nông nghiệp, bao bọc dinh
                    dưỡng, các khoáng chất bên trong. Các hạt nano này không độc
                    hại, thân thiện môi trường, giúp kích thích sinh trưởng,
                    tăng tỉ lệ nảy mầm, ra hoa, đậu quả và tăng năng suất cây
                    trồng; đồng thời ngăn chặn, ức chế và phòng trừ các mầm bệnh
                    do nấm và khuẩn gây nên.
                    <br />
                  </p>
                </article>
              </div>
              <div class="column is-5">
                <figure class="image">
                  <image-data
                    url="https://storage.googleapis.com/meganic_assets/about-tech-green-house-tech.svg"
                    alt=""
                    @loaded="onImageLoad"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <h1 class="title has-text-centered is-1">
          Công nghệ
        </h1>
        <div class="section is-size-5 has-text-centered">
          <span class="is-hand-script is-size-4" style="color: #11998E;">
            Metro Organico</span
          >
          sở hữu độc quyền công nghệ nano đã được phòng R&D phát triển.
          <br />

          Sản phẩm của chúng tôi cho ra năng suất vượt trội so với canh tác
          truyền thống và các phương pháp thuỷ canh thông thường.
        </div>
        <div class="illustrator">
          <image-data
            url="https://storage.googleapis.com/meganic_assets/charts/benefit-hydro.png"
          ></image-data>
        </div>

        <!-- <table class="table is-striped" data-scroll>
          <thead>
            <tr>
              <th><abbr title="crop">Môt vụ canh tác</abbr></th>
              <th><abbr title="soil">Phương pháp thông thường</abbr></th>
              <th><abbr title="hydroponics">Phương pháp thuỷ canh</abbr></th>
              <th><abbr title="yeilds">Năng suất </abbr></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Đậu nành</td>
              <td>270 kg</td>
              <td>700 kg</td>
              <td>260%</td>
            </tr>
            <tr>
              <td>Đậu đen</td>
              <td>5 tấn</td>
              <td>21 tấn</td>
              <td>420%</td>
            </tr>
            <tr>
              <td>Đậu Hà Lan</td>
              <td>1 tấn</td>
              <td>9 tấn</td>
              <td>900%</td>
            </tr>
            <tr>
              <td>Lúa mì</td>
              <td>270 kg</td>
              <td>1,860 kg</td>
              <td>690%</td>
            </tr>
            <tr>
              <td>Gạo</td>
              <td>454 kg</td>
              <td>2,268 kg</td>
              <td>500%</td>
            </tr>
            <tr>
              <td>Lúa mạch</td>
              <td>454 kg</td>
              <td>1134 kg</td>
              <td>250%</td>
            </tr>
            <tr>
              <td>Củ dền</td>
              <td>4 tấn</td>
              <td>12 tấn</td>
              <td>300%</td>
            </tr>
            <tr>
              <td>Khoai Tây</td>
              <td>8 tấn</td>
              <td>70 tấn</td>
              <td>875%</td>
            </tr>
            <tr>
              <td>Rau cải xanh</td>
              <td>9 tấn</td>
              <td>21 tấn</td>
              <td>233%</td>
            </tr>
            <tr>
              <td>Cà chua</td>
              <td>10 tấn</td>
              <td>300 tấn</td>
              <td>3000%</td>
            </tr>
            <tr>
              <td>Dưa leo</td>
              <td>7 tấn</td>
              <td>28 tấn</td>
              <td>400%</td>
            </tr>
          </tbody>
        </table> -->

        <!-- <mq-responsive target="sm-">
          <div class="subtitle has-text-left is-size-6">
            Nguồn: <b>"Hydroponic food production".</b> <br />
            Tác giả: <b>Howard M Resh.</b>
          </div>
        </mq-responsive> -->
        <mq-responsive target="xs+">
          <div class="subtitle has-text-centered ">
            Nguồn: <b>"Hydroponic food production".</b> <br />
            Tác giả: <strong>Howard M. Resh.</strong>
          </div>
        </mq-responsive>
      </div>
    </section>

    <section class="section">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter">
          <h1 class="title has-text-centered">
            Công đoạn chính.
          </h1>
          <br />
          <h2 class="is-size-6 has-text-justified">
            Metro Organico tự tin mang đến những sản phẩm rau củ sạch sẽ và an
            toàn.<br />
            Ngoài các dung dịch hữu cơ Metro Organico phát triển, tất cả các hạt
            giống Metro Organico sử dụng đều không biến đổi gene, xuất xứ từ Mỹ.

            <br />
            Bạn có thể ăn ngay sản phẩm của Metro Organico vừa được giao tới
            trước cửa mà không cần phải trải qua bất kì công đoạn tẩy rửa nào,
            và chúng tôi cùng gia đình cũng ăn như vậy.
          </h2>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container timeline-container is-relative">
        <div
          class="timeline-item-card"
          data-scroll
          v-for="content in contents"
          :key="content.id"
        >
          <div class="timeline-line"></div>
          <div class="timeline-icon has-text-centered mt-1">
            <p>
              <b>{{ content.id }}</b>
            </p>
          </div>
          <figure class="timeline-figure">
            <image-data :url="content.imageUrl" alt="" />
          </figure>
          <h2 class="subtitle text-content">
            {{ content.title }}
          </h2>
        </div>
      </div>
    </section>

    <section class="columns">
      <div class="column is-half is-offset-one-quarter">
        <h1
          class="title is-hand-script has-text-centered"
          style="color:#2F80ED;"
        >
          Ăn tươi xanh, sống an lành
        </h1>
      </div>
    </section>

    <section class="floating-image is-relative">
      <div class="half-floating-background"></div>
      <figure class="image-iot">
        <image-data
          url="https://storage.googleapis.com/meganic_assets/about-tech-IoT-faming.svg"
          alt=""
        />
      </figure>
    </section>
  </div>
</template>

<script>
import ScrollOut from 'scroll-out'
import { MqResponsive } from 'vue3-mq'

export default {
  name: 'AboutTech',
  components: { MqResponsive },
  data() {
    return {
      contents: [
        {
          id: 1,
          title:
            'Hạt giống được ươm vào từng giá thể có chứa các hạt nano nhả dinh dưỡng.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-03-plant-cone.svg'
        },
        {
          id: 2,
          title: 'Quá trình nảy mầm được giám sát chặt chẽ.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-seeding.svg'
        },
        {
          id: 3,
          title: 'Kiểm nghiệm cây non đủ tiêu chuẩn.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-baby-plant.svg'
        },
        {
          id: 4,
          title:
            'Cây non được đưa lên giàn thủy canh trong môi trường phòng sạch',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-08-green-house-01.svg'
        },
        {
          id: 5,
          title:
            'Toàn bộ quá trình cung cấp dưỡng chất sử dụng dung dịch nano có chiết suất hoàn toàn tự nhiên, thân thiện môi trường. ',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-14-plantation-floor-horizontal-02.svg'
        },
        {
          id: 6,
          title:
            'Toàn bộ hệ thống cung cấp dinh dưỡng, phun sương, tưới nước đều được tự động hóa với các số liệu được ghi nhận mỗi 24h.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-hydroponics-system.svg'
        },
        {
          id: 7,
          title:
            'Nhiệt độ trong nhà kính được kiểm soát bởi hệ thống phun sương tự động.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-01-water-dripping-system.svg'
        },
        {
          id: 8,
          title:
            'Mọi thông tin được kiểm soát từ trung tâm thông tin cùa Metro Organico.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-plant-dashboard.svg'
        },
        {
          id: 9,
          title:
            'Khác với quy trình chăm cây, quá trình thu hoạch được thực hiện thủ công nhằm đảm bảo sản phẩm tới tay bạn có chất lượng tốt nhất.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-check-to-harvest.svg'
        },
        {
          id: 10,
          title: 'Sản phẩm được đóng gói.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-04-final-fruit-basket.svg'
        },
        {
          id: 11,
          title:
            'Rau củ được phân loại, và phân phối trong vòng 2h sau khi thu hoạch.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-shelf-veggies.svg'
        },
        {
          id: 12,
          title: 'Giao tận tay đến cho người dùng.',
          imageUrl:
            'https://storage.googleapis.com/meganic_assets/about-tech-delivery.svg'
        }
      ]
    }
  },
  mounted() {
    this.$loadingOverlay.show()

    this.so = ScrollOut({
      scope: this.$el,
      once: true
    })
  },
  destroyed() {
    this.so.teardown()
  },
  methods: {
    onImageLoad: function() {
      let self = this
      this.$nextTick(() => {
        self.$loadingOverlay.hide()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

#about-tech {
  color: $text-color;
  background-color: $text-inverted-color;
}

.jumbotron.section {
  background: rgb(74, 0, 224);
  background: -moz-linear-gradient(
    45deg,
    rgba(74, 0, 224, 1) 0%,
    rgba(142, 45, 226, 1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(74, 0, 224, 1) 0%,
    rgba(142, 45, 226, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(74, 0, 224, 1) 0%,
    rgba(142, 45, 226, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4a00e0",endColorstr="#8e2de2",GradientType=1);
}

.hero .subtitle,
.hero .title,
.hero article {
  color: $text-inverted-color;
}

.table {
  margin-left: auto;
  margin-right: auto;
}

.floating-image {
  min-height: 400px;
  max-height: 800px;
  width: 100%;
}

.illustrator {
  margin: auto;
  width: 70%;
}
.image-iot {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-height: 400px;
  min-width: 400px;
  max-height: 600px;
  max-width: 600px;
  z-index: 5;
}

.half-floating-background {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 50%;
  background-color: $text-color;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}

// Timeline markup
.timeline-line {
  position: absolute;
  top: 0px;
  left: -2px;
  width: 5px;
  height: 100%;
  border-radius: 5px;
  background-color: $secondary-color;
}

.timeline-item-card {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  width: 500px;
  height: 300px;
}

.timeline-item-card .timeline-figure {
  position: absolute;
  left: 100px;
  min-width: 200px;
  min-height: 200px;
  width: 20px;
  height: 200px;
}

.timeline-item-card .timeline-figure img {
  min-width: 100px;
  min-height: 100px;
  width: 200px;
  height: 200px;
}

.timeline-item-card .text-content {
  position: absolute;
  top: 210px;
  left: 50px;
}

.timeline-icon {
  position: absolute;
  top: calc(30%);
  left: -20px;
  transform: translateY(50%);
  background-color: #fcfcfc;
  border: 5px solid $secondary-color;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 600px) {
  .floating-image {
    min-height: 300px;
    max-height: 600px;
    width: 100%;
  }
  .image-iot {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    min-height: 200px;
    min-width: 200px;
    max-height: 300px;
    max-width: 300px;
    z-index: 5;
  }

  .half-floating-background {
    position: absolute;
    width: 100%;
    height: 50%;
    top: 50%;
    background-color: $text-color;
    border-top-left-radius: 50px !important;
    border-top-right-radius: 50px;
    border: none;
  }

  .timeline-item-card {
    width: 300px;
    height: 250px;
  }

  .timeline-item-card .timeline-figure {
    position: absolute;
    left: 50px;
    min-width: 100px;
    min-height: 100px;
    width: 150px;
    height: 150px;
  }

  .timeline-item-card .timeline-figure img {
    position: absolute;
    left: 25px;
    top: 25px;
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    height: 100px;
  }

  .timeline-item-card .text-content {
    position: absolute;
    top: 130px;
    left: 25px;
    font-size: 1rem;
  }

  .illustrator {
    width: 100%;
  }
}
</style>

<template>
  <form @keyup.enter="handleLogin">
    <div class="field">
      <p class="control has-icons-left has-icons-right">
        <input
          class="input outlined"
          type="text"
          placeholder="Email"
          v-model="user.username"
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="at" />
        </span>
        <span class="icon is-small is-right">
          <font-awesome-icon icon="check" />
        </span>
      </p>
    </div>
    <div class="field has-addons">
      <div class="control is-expanded has-icons-left">
        <input
          class="input"
          :type="textOrPassword"
          placeholder="Password"
          autocomplete="on"
          v-model="user.password"
        />
        <span class="icon is-small is-left">
          <font-awesome-icon icon="lock" />
        </span>
      </div>
      <div class="control">
        <button
          class="button is-ghost"
          @click="
            () => {
              this.textOrPassword =
                this.textOrPassword === 'password' ? 'text' : 'password'
            }
          "
        >
          <font-awesome-icon icon="eye" />
        </button>
      </div>
    </div>
    <div class="field">
      <p class="control">
        <button
          :class="{ focused: isActive }"
          class="button is-fullwidth is-success"
          @click="handleLogin"
        >
          Login
        </button>
      </p>
    </div>

    <a @click="emitChangeForm(tabs.RESET)"><p>Forgot your password?</p></a>
    <div class="divider has-text-centered">
      <span class="dash"></span>
      <p class="py-5">OR</p>
      <span class="dash"></span>
    </div>
    <div class="field">
      <p class="control">
        <button
          @click="emitChangeForm(tabs.REGISTER)"
          :class="{ focused: isActive }"
          class="button is-fullwidth is-success is-outlined"
        >
          Register
        </button>
      </p>
    </div>
  </form>
</template>

<script>
import User from '@/models/user'
import TABS from '@/components/auth/tabs'

export default {
  name: 'AuthLogin',
  data() {
    return {
      user: new User(),
      loading: false,
      message: '',
      isActive: false,
      tabs: TABS,
      textOrPassword: 'password'
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    emitChangeForm(tab) {
      this.$emit('clicked', tab)
    },
    handleLogin() {
      if (this.user.username && this.user.password) {
        this.$loadingOverlay.show()
        this.$store
          .dispatch('auth/login', this.user)
          .then(data => {
            if (data.token) {
              this.$router.push({ name: 'admin-main' })
            } else if (data.error) {
              alert(data.error)
            }
          })
          .finally(() => this.$loadingOverlay.hide())
      }
    }
  }
}
</script>

<style></style>

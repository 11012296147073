<template>
  <section id="gallery" class="section">
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-parent is-vertical">
          <!-- left gallery -->
          <div id="none01" class="tile is-child">
            <p class="title is-size-2">
              Hạn hán vùng châu thổ MEKONG ngày càng trầm trọng.
            </p>
          </div>
          <div id="pic01" class="tile is-child">
            <image-data
              url="https://storage.googleapis.com/meganic_assets/storytelling/drought01.png"
            ></image-data>
          </div>
        </div>

        <div class="tile is-parent">
          <!-- right gallery -->
          <div class="tile is-child">
            <image-data
              url="https://storage.googleapis.com/meganic_assets/storytelling/drought05.png"
            ></image-data>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <!-- right gallery -->
          <div class="tile is-child">
            <image-data
              url="https://storage.googleapis.com/meganic_assets/storytelling/drought03.png"
            ></image-data>
          </div>
        </div>

        <div class="tile is-parent is-vertical">
          <!-- left gallery -->
          <div id="none01" class="tile is-child">
            <p class="title is-size-2">
              Nông nghiệp truyền thống không còn bền vững.
            </p>
          </div>
          <div id="pic01" class="tile is-child">
            <image-data
              url="https://storage.googleapis.com/meganic_assets/storytelling/drought04.png"
            ></image-data>
          </div>
          <div id="none02" class="tile is-child"></div>
        </div>
      </div>
    </div>

    <section id="story" class="container">
      <article class="section">
        <h1 class="title">
          <span class="is-size-3">Con tim + Trí tuệ =</span>
          <span class="is-hand-script is-size-1" style="color: #11998E;">
            Metro Organico</span
          >
        </h1>
        <p class="is-content is-size-5 has-text-justified">
          Biến đổi khí hậu đã làm thay đổi đáng kể điều kiện canh tác ở Việt
          Nam: đất đai bạc màu, nhiễm mặn, thiếu nước... Để năng suất được như
          cũ, lượng hóa chất, phân bón được thêm vào càng lúc càng nhiều hơn,
          dẵn tới tình trạng thoái hóa đất càng nghiêm trọng. Vòng lặp này đòi
          hỏi một giải pháp tốt hơn cho việc canh tác. Metro Organico nhận thấy
          việc mang lại một mô hình trồng trọ mới, tiết kiệm nước, không hóa
          chất mà vẫn đảm bảo sản phẩm có năng suất cao, và chất lượng tốt nhất
          là một vấn đề cấp bách.
          <br />
        </p>
      </article>

      <div class="container">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-vertical">
            <div class="tile is-child">
              <image-data
                url="https://storage.googleapis.com/meganic_assets/storytelling/diagram01.png"
              ></image-data>
            </div>

            <div class="tile is-child section">
              <p
                class="title is-content has-text-centered is-size-5 has-text-justified"
              >
                Hình 1: Tỉ lệ nước tưới tiêu cho nông nghiệp truyền thống.
              </p>
              <p class="has-text-centered has-text-justified">
                Ref: AQUASTAT Database - Food and Agriculture Organization of
                the United Nations
              </p>
            </div>
          </div>
          <div class="tile is-parent is-vertical">
            <div class="tile is-child">
              <image-data
                url="https://storage.googleapis.com/meganic_assets/storytelling/diagram02.png"
              ></image-data>
            </div>

            <div class="tile is-child section">
              <p
                class="title is-content has-text-centered is-size-5 has-text-justified"
              >
                Hình 2: Tỉ lệ lượng ô nhiễm đạm. Giá trị dương (màu vàng đến đỏ)
                cho thấy quốc gia sử dụng phân đạm quá mức mà không làm năng
                suất cây trồng tăng.
              </p>
              <p class="has-text-centered has-text-justified">
                Ref: Wuepper, D. et al, Nature Food, 1(11), 713-719, 2020.
              </p>
            </div>
          </div>
        </div>
      </div>

      <article class="section">
        <p class="is-content is-size-5 has-text-justified">
          Công nghệ thủy canh là một trong những mô hình nông nghiệp hiện đại,
          tiên tiến trong việc trồng cây không dùng đất mà chỉ dựa vào giá thể
          và môi trường dinh dưỡng. Kỹ thuật hiện đại này không chỉ giải quyết
          được lượng lớn nước cần dùng cho phục vụ tưới tiêu truyền thống mà còn
          đảm bảo lượng nguyên tố khoáng dinh dưỡng cho cây được kiểm soát vừa
          đủ và đúng lúc trong những giai đoạn phát triển, hướng đến một nền
          nông nghiệp xanh, nông sản sạch, đảm bảo không tồn dư chất trong nông
          sản và không ô nhiễm nguồn tài nguyên đất, nước. Nhưng hệ thống thủy
          canh cũng có những điểm yếu là rất dễ nhiễm nấm mốc, và hầu như không
          chịu được thời tiết nắng nóng.
          <br />
          <br />
          Giải pháp của
          <span class="is-hand-script is-size-4" style="color: #11998E;"
            >Metro Organico
          </span>
          giúp cây trồng hấp thụ nước, dinh dưỡng, và các tài nguyên cần thiết
          khác một cách hiệu quả nhất, ngay cả trong thời tiết nắng nóng tại
          Việt Nam, và với cả những giống rau tươi ngon xứ lạnh.
          <span class="is-hand-script is-size-4" style="color: #11998E;"
            >Metro Organico
          </span>
          nghiên cứu và thiết kế các hạt nano bao bọc dưỡng chất nuôi cây. Tùy
          vào từng giai đoạn phát triển khác nhau của cây, các hạt nano với công
          nghệ vượt trội này sẽ giúp quá trình cung cấp dưỡng chất cần thiết đến
          rễ, thân, lá diễn ra dễ dàng, tối ưu hóa năng suất và nâng cao chất
          lượng sản phẩm. Các hạt nano này được chiết suất 100% từ sản phẩm tự
          nhiên. Chúng tôi cam kết không sử dụng hóa chất độc hại trong suốt quá
          trình phát triển của cây. Do đó, chúng tôi cung cấp giải pháp nông
          nghiệp bền vững.
        </p>
      </article>

      <article class="section">
        <h1 class="title">
          Hướng tới tương lai 4.0
        </h1>
        <p class="is-content is-size-5 has-text-justified">
          Giải pháp của Metro Organico giúp cây trồng sử dụng nước, dinh dưỡng,
          và các tài nguyên khác hiệu quả nhất. Không có bất kỳ giai đoạn phát
          triển nào của cây có liên quan đến các hóa chất độc hại

          <br />
          <br />
          Với việc làm chủ công nghệ dưỡng chất cũng như tự động hoá,
          <span class="is-hand-script is-size-4" style="color: #11998E;"
            >Metro Organico
          </span>
          tự tin sẽ đóng góp cho quá trình chuyển mình sang nền nông nghiệp hiện
          4.0. Chúng tôi sẵn sàng chia sẻ giải pháp cho các nhà nông, cũng như
          hộ gia đình.
          <br />
          <br />
          Và những gì tốt cho hành tinh này, thì cũng tốt cho bạn.
        </p>
      </article>
    </section>
  </section>
</template>

<script>
export default {
  name: 'AboutMotivation'
}
</script>

<style lang="scss" scoped>
p {
  color: #b66051;
}

#pic01 {
  border: solid 1rem #b66051;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#gallery {
  background-color: #232a34;
  padding: 15rem auto;
}

#story {
  margin-top: 15rem;
  background-color: #fafafa;

  .title {
    color: #11998e;
  }
  p {
    color: #3e3e3e;
  }
}

// mobile
@media only screen and (max-width: 600px) {
  #story {
    margin-top: 7rem;
  }
  #pic01 {
    border: solid 0.5rem #b66051;
    object-fit: contain;
  }
}
</style>

<template>
  <div id="blogs">
    <AppNav />
    <div class="hero is-fullheight is-danger is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column pt-5">
              <h1 class="title pt-5">
                Nội dung đang được hoàn thiện
              </h1>
              <h1 class="Subtitle">Xin quay lại trang vào thời điểm khác</h1>
            </div>

            <div class="column">
              <figure class="image">
                <img
                  src="https://storage.googleapis.com/meganic_assets/404-not-found.svg"
                  alt=""
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNav from '@/components/AppNav.vue'

export default {
  name: 'BlogPost',
  components: {
    AppNav
  }
}
</script>

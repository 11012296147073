<template>
  <div id="home-contact" class="section home-contact-us">
    <div class="container">
      <div class="columns is-vcentered is-centered is-tablet+">
        <div class="column is-half">
          <div class="box">
            <h1 class="title is-size-2 is-hand-script has-text-centered">
              Liên hệ
            </h1>

            <div class="field">
              <label class="label">Tên</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  :class="nameStatus"
                  type="email"
                  placeholder="Nhập tên của bạn"
                  v-model="nameValue"
                />
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="user" />
                </span>

                <template v-if="isNameValid">
                  <span class="icon is-small is-right">
                    <font-awesome-icon icon="check" />
                  </span>
                </template>
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control has-icons-left has-icons-right">
                <input
                  class="input"
                  :class="emailStatus"
                  type="email"
                  placeholder="Nhập email của bạn"
                  v-model="emailValue"
                />
                <span class="icon is-left">
                  <font-awesome-icon icon="at" />
                </span>

                <template v-if="isEmailValid">
                  <span class="icon is-small is-right">
                    <font-awesome-icon icon="check" />
                  </span>
                </template>
              </div>
            </div>

            <div class="field">
              <label class="label">Lý do</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="selectedReasonKey">
                    <div v-for="(val, key, index) in reasons" :key="key">
                      <option :value="key" :key="index">{{ val }}</option>
                    </div>
                  </select>
                </div>
              </div>
            </div>

            <div class="field">
              <label class="label">Nội dung</label>
              <div class="control">
                <textarea
                  v-model="inquiry"
                  class="textarea"
                  placeholder="Nhập nội dung"
                ></textarea>
              </div>
            </div>

            <div class="control">
              <button class="button is-primary is-fullwidth">
                <p class="is-size-5">GỬI</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeContactUs',
  data() {
    return {
      nameValue: '',
      isNameValid: false,
      nameStatus: '',

      emailValue: '',
      isEmailValid: false,
      emailStatus: '',

      selectedReasonKey: 'partner',
      reasons: {
        partner: 'Làm đối tác',
        visitor: 'Tham quan công nghệ',
        promotion: 'Khuyến mãi đặc biệt',
        default: 'Lý do khác'
      },

      inquiry: ''
    }
  },

  watch: {
    nameValue: function() {
      let isValid = this.nameValue.length > 1
      if (isValid) {
        this.isNameValid = true
        this.nameStatus = 'is-success'
      } else {
        this.isNameValid = false
        this.nameStatus = ''
      }
    },
    emailValue: function() {
      let isSuccess = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this.emailValue
      )
      if (isSuccess) {
        this.isEmailValid = true
        this.emailStatus = 'is-success'
      } else {
        this.isEmailValid = false
        this.emailStatus = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/app-variables.scss';

#home-contact {
  background: url('https://storage.googleapis.com/meganic_assets/landscape-contact-bg.png')
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>

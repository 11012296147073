// Authentication module

import AuthService from '@/services/auth.service'

const STORAGE_USER = 'user'
const user = JSON.parse(localStorage.getItem(STORAGE_USER))
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: () => initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', response)
          return Promise.resolve(response)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },

    countDownSilentRefresh({ commit }, username) {
      return AuthService.refresh(username).then(
        userResponse => {
          commit('refreshSuccess', userResponse)
          return Promise.resolve(userResponse)
        },
        error => {
          commit('refreshFailure')
          return Promise.reject(error)
        }
      )
    },

    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },

    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess', response)
          return Promise.resolve(response)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    }
  }, // end actions

  getters: {
    getUser(state) {
      return state.user
    }
  },

  mutations: {
    loginSuccess(state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    loginFailure(state) {
      state.status = { loggedIn: false }
      state.user = null
    },
    logout(state) {
      state.status = { loggedIn: false }
      state.user = null
    },
    registerSuccess(state, user) {
      state.status = { loggedIn: true }
      state.user = user
    },
    registerFailure(state) {
      state.status = { loggedIn: false }
      state.user = null
    },
    refreshSuccess(state, user) {
      state.user = user
      state.status = { loggedIn: true }
    },
    refreshFailure(state) {
      state.status = { loggedIn: false }
      state.user = null
    }
  }
}

<template lang="pug">
div.container
  ProfileCard(:initialProfile="profile")
            
</template>

<script>
import ProfileCard from '@/components/profiles/ProfileCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ProfileMain',
  components: {
    ProfileCard
  },
  computed: {
    ...mapGetters({
      profile: 'profile/getProfile'
    })
  }
}
</script>

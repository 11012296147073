<template>
  <nav
    class="navbar is-fixed-top is-light"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="container">
      <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
          <img
            height="80"
            src="https://storage.googleapis.com/meganic_assets/metro%20organico%20logo.png"
            alt="Metro Organico, a fresh start for a young new generation"
          />
        </router-link>

        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbar-navigation"
          @click="toggleMenu()"
          :class="{ 'is-active': isOpen }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbar-navigation"
        class="navbar-menu"
        :class="{ 'is-active': isOpen }"
      >
        <div class="navbar-start">
          <router-link
            class="navbar-item"
            v-smooth-scroll
            href="#hydro-introduction"
            to="/"
            :active="matchRoute('hydro-introduction')"
          >
            Trang chủ
          </router-link>

          <router-link
            class="navbar-item"
            v-smooth-scroll
            to="/products"
            :active="matchRoute('products')"
            @click.native="didClick()"
          >
            Sản phẩm
          </router-link>
          <router-link
            class="navbar-item"
            v-smooth-scroll
            to="/investors"
            :active="matchRoute('investors')"
            @click.native="didClick()"
          >
            Chung sức
          </router-link>

          <!-- <router-link class="navbar-item" to="/blogs" @click.native="didClick">
            Blogs
          </router-link> -->

          <div class="navbar-item has-dropdown is-hoverable">
            <router-link
              class="navbar-link"
              to="/about-us/tech"
              @click.native="didClick()"
            >
              Về Công Ty
            </router-link>

            <div class="navbar-dropdown">
              <router-link
                class="navbar-item"
                to="/about-us/team"
                @click.native="didClick"
              >
                <p>Đội Ngũ Nhân Sự</p>
              </router-link>
              <router-link
                class="navbar-item"
                to="/about-us/tech"
                @click.native="didClick()"
              >
                Công Nghệ
              </router-link>
              <hr class="navbar-divider" />
              <router-link
                class="navbar-item"
                to="/about-us/motivation"
                @click.native="didClick()"
              >
                Động lực
              </router-link>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <div v-if="status.loggedIn">
              <div class="buttons">
                <button class="button is-ghost" @click="logOut">
                  Logout
                </button>
                <button @click="redirectToAdmin" class="button is-outlined">
                  <span style="padding-right: 5px;"
                    ><font-awesome-icon icon="user"
                  /></span>
                  <span>{{ profile ? profile.email : 'empty' }}</span>
                </button>
              </div>
            </div>
            <button
              class="button is-ghost"
              href="#"
              @click="
                () => {
                  this.shouldShowBetaModal = true
                }
              "
            >
              Beta
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" :class="{ 'is-active': shouldShowBetaModal }">
      <div
        class="modal-background"
        @click="
          () => {
            this.shouldShowBetaModal = false
          }
        "
      ></div>
      <div class="modal-content">
        <article class="message is-warning">
          <div class="message-body">
            Xin cảm ơn quý khác đã ủng hộ.
            <br />
            <b class=" title is-5 is-hand-script has-text-primary">
              Metro Organico</b
            >
            vẫn còn đang trong quá trình hoàn thiện hệ thống phân phối sản phẩm
            online.
            <br />
            Mọi đơn hàng xin liên hệ trực tiếp với cửa hàng.
          </div>
        </article>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="
          () => {
            this.shouldShowBetaModal = false
          }
        "
      ></button>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AppNav',
  data() {
    return {
      isOpen: false,
      isActiveItem: false,
      shouldShowBetaModal: false
    }
  },
  created() {},

  computed: {
    ...mapState('auth', ['status', 'user']),
    ...mapGetters({
      profile: 'profile/getProfile'
    })
  },

  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
    didClick() {
      if (this.isOpen) {
        this.isOpen = false
      }
    },
    matchRoute(routeName) {
      this.isActiveItem = false
      if (this.$route.matched.some(({ name }) => name === routeName)) {
        this.isActiveItem = true
      }
    },
    async logOut() {
      this.$store.dispatch('auth/logout').then(() => {
        if (this.$route.name !== 'admin-login') {
          this.$router.push('/admin/login')
        }
        this.isOpen = false
      })
    },
    redirectToAdmin() {
      if (this.$route.name !== 'admin-login') {
        this.$router.push({ name: 'admin-main' })
      }
    }
  },
  watch: {
    status(val) {
      if (val.loggedIn && val.loggedIn === true) {
        this.$loadingOverlay.show()
        this.$store
          .dispatch('profile/getProfileByUsername', this.user.username)
          .then(() => {
            this.$loadingOverlay.hide()
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#navigation {
  a.navbar-item {
    font-family: 'Kodchasan', sans-serif;
  }
  .navbar-item img {
    max-height: 3em;
  }

  .button.is-primary {
    background-color: #fcfcfc;
  }
}
</style>

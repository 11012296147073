export default {
  "about": {
    "headline_about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhân sự"])},
    "content_about_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tại Metro Organico, chúng tôi, gồm các nhà khoa học, kỹ sư, và chuyên gia đang làm việc tại Mỹ, Đức, Việt Nam và Hàn Quốc, muốn định nghĩa lại hình ảnh chân lấm tay bùn của người trồng trọt bằng khoa học và công nghệ cao. Metro Organico muốn được cùng người trồng trọt cung cấp rau củ sạch, tươi ngon cho mọi bữa ăn thành thị tại Việt Nam. Chúng tôi có kế hoạch dài hơi để làm việc này, nhưng rất cần sự ủng hộ của các bạn."])},
    "content_about_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "headline_vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tầm nhìn chiến lược"])},
    "content_vision_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tại Metro Organico, chúng tôi định nghĩa lại nông nghiệp đô thị bằng công nghệ nano. Giải pháp của Metro Organico giúp cây trồng hấp thụ nước, dinh dưỡng, và các tài nguyên cần thiết khác một cách hiệu quả nhất. "])},
    "content_vision_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi cam kết không sử dụng hóa chất độc hại trong suốt quá trình phát triển của cây. Metro Organico nghiên cứu và thiết kế các hạt nano bao bọc dưỡng chất nuôi cây. Tùy vào từng giai đoạn phát triển khác nhau của cây, các hạt nano với công nghệ vượt trội này sẽ giúp quá trình cung cấp dưỡng chất cần thiết đến rễ, thân, lá diễn ra dễ dàng, tối ưu hóa năng suất và nâng cao chất lượng sản phẩm."])},
    "conlusion_vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tại Metro Organico, chúng tôi cung cấp giải pháp nông nghiệp bền vững!"])}
  }
}
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { auth } from '@/store/auth.module'
import { profile } from '@/store/profile.module'
import AuthService from '@/services/auth.service'
// import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from 'snakecase-keys'
import axios from 'axios'

import { camelCase } from 'lodash'

const camelizeKeys = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v))
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key])
      }),
      {}
    )
  }
  return obj
}

axios.defaults.withCredentials = true

axios.interceptors.response.use(
  response => {
    let newData = camelizeKeys(response.data)
    response.data = newData
    return Promise.resolve(response)
  },
  error => {
    if (error.response) {
      if (error.response.status == 401) {
        AuthService.logout()
      }
    }

    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  config => {
    if (
      config.url.includes('/admin') ||
      config.url.includes('/profiles') ||
      config.url.includes('/images')
    ) {
      const STORAGE_USER = 'user'
      const user = JSON.parse(localStorage.getItem(STORAGE_USER))
      config.headers.Authorization = `Bearer ${user.token}`
    }
    if (config.data) {
      config.data = snakecaseKeys(config.data, { deep: true })
    }
    return Promise.resolve(config)
  },
  error => {
    return Promise.reject(error)
  }
)

const store = createStore({
  modules: {
    auth,
    profile
  },
  plugins: [createPersistedState()]
})

export default store
